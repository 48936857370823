<div class="row admin">
  <p class="admin"><b>{{ 'admin.importgrund.new' | translate }}</b></p>
</div>
<div class="row admin">
  <div class="col-md-1.5 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.de' | translate }}</b></p></div>
  <div class="col-md-1.5 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.fr' | translate }}</b></p></div>
  <div class="col-md-1.5 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.it' | translate }}</b></p></div>
  <div class="col-md-1 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.sort' | translate }}</b></p></div>
  <div class="col-md-1 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.kommentar' | translate }}</b></p></div>
  <div class="col-md-1 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.zulassungsNrCh' | translate }}</b></p></div>
  <div class="col-md-1 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.lieferEngpass' | translate }}</b></p></div>
  <div class="col-md-1 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.importgrund.column.aktiv' | translate }}</b></p></div>
  <div class="col-md-2.5 pl-0 mb-0"></div>
</div>
<div class="row admin">
  <form [formGroup]="newGrundForm" (ngSubmit)="onSubmit()">
    <div class="col-md-1.5 pl-0 mb-0">
      <input id="bezeichnungDe_new" class="admin-settings" formControlName="bezeichnungDe_new" maxlength="255">
    </div>

    <div class="col-md-1.5 pl-0 mb-0">
      <input id="bezeichnungFr_new" class="admin-settings" formControlName="bezeichnungFr_new" maxlength="255">
    </div>

    <div class="col-md-1.5 pl-0 mb-0">
      <input id="bezeichnungIt_new" class="admin-settings" formControlName="bezeichnungIt_new" maxlength="255">
    </div>

    <div class="col-md-1 pl-0 mb-0">
      <input id="sortierung_new" class="admin-settings" formControlName="sortierung_new">
    </div>

    <div class="col-md-1 pl-0 mb-0">
      <input type="checkbox" id="kommentarPflicht_new" formControlName="kommentarPflicht_new">
    </div>

    <div class="col-md-1 pl-0 mb-0">
      <input type="checkbox" id="zulassungsNrChAnzeigen_new" formControlName="zulassungsNrChAnzeigen_new">
    </div>

    <div class="col-md-1 pl-0 mb-0">
      <input type="checkbox" id="lieferEngpassAnzeigen_new" formControlName="lieferEngpassAnzeigen_new">
    </div>

    <div class="col-md-1 pl-0 mb-0">
      <input type="checkbox" id="aktiv_new" formControlName="aktiv_new">
    </div>

    <div class="col-md-2.5 pl-0">
      <button class="btn btn-sm admin-settings" type="submit" [disabled]="newGrundForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" [title]="'global.cancel' | translate" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
