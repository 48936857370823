<div class="row admin">
  <p class="admin"><b>{{ 'admin.deaktivierungsgrund.new' | translate }}</b></p>
</div>
<div class="row admin">
  <div class="col-sm-1 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.deaktivierungsgrund.column.sort' | translate }}</b></p></div>
  <div class="col-sm-3 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'global.sprache.de' | translate }}</b></p></div>
  <div class="col-sm-3 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'global.sprache.fr' | translate }}</b></p></div>
  <div class="col-sm-3 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'global.sprache.it' | translate }}</b></p></div>
  <div class="col-sm-2 pl-0 mb-0"></div>
</div>
<form [formGroup]="newGrundForm" (ngSubmit)="onSubmit()">
  <div class="row admin">
    <div class="col-sm-1 pl-0 mb-0">
      <input id="sortierung_new" class="admin-settings" formControlName="sortierung_new">
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <input id="bezeichnungDe_new" class="admin-settings" formControlName="bezeichnungDe_new" maxlength="255">
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <input id="bezeichnungFr_new" class="admin-settings" formControlName="bezeichnungFr_new" maxlength="255">
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <input id="bezeichnungIt_new" class="admin-settings" formControlName="bezeichnungIt_new" maxlength="255">
    </div>

    <div class="col-sm-2 pl-0 mb-0">
      <button class="btn btn-sm admin-settings" type="submit" [disabled]="newGrundForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" [title]="'global.cancel' | translate" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="row admin mb-15">
    <div class="col-sm-3 pl-0 mb-0 col-sm-offset-1">
      <textarea id="textDe_new" class="admin-settings" formControlName="textDe_new"></textarea>
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <textarea id="textFr_new" class="admin-settings" formControlName="textFr_new"></textarea>
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <textarea id="textIt_new" class="admin-settings" formControlName="textIt_new"></textarea>
    </div>
  </div>
</form>
