import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  template: `
    <div [hidden]="!active"
         [attr.aria-selected]="active"
         [attr.aria-controls]="tabTitle"
         id="{{ tabId }}"
         class="tab-pane">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  @Input() tabTitle: string;
  @Input() tabId: string;
  @Input() contentLength: string;
  @Input() active = false;
  @Input() autoWidth = false;
}
