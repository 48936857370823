import { Component, OnInit } from '@angular/core';
import { BackendService } from '@app/shared/services/backend.service';

@Component({
  selector: 'app-admin-check',
  templateUrl: './check.component.html'
})
export class CheckComponent implements OnInit {

  checkRunning = false;
  checkOk = false;

  constructor(private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.checkShort();
  }

  checkShort() {
    this.checkRunning = true;
    this.backendService.checkBackend()
      .subscribe({
        next: () => {
          this.checkOk = true;
          this.checkRunning = false;
        },
        error: error => {
          console.error(error);
          this.checkOk = false;
          this.checkRunning = false;
        }
      });
  }
}
