import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { CollapseContentDirective } from '@app/shared/collapse-content.directive';

@Directive({
  selector: '[appCollapse]',
  exportAs: 'appCollapse'
})
export class CollapseDirective {

  @HostBinding('class.collapsed')
  collapsed = true;

  private targets: CollapseContentDirective[];

  @Input()
  public set appCollapse(target: CollapseContentDirective | CollapseContentDirective[]) {
    this.targets = [].concat(target);
  }

  @HostBinding('attr.aria-expanded')
  public get expanded() {
    return !this.collapsed;
  }

  @HostListener('click')
  toggle() {
    this.collapsed = !this.collapsed;
    this.targets.every(target => target.showing = !this.collapsed);
  }
}
