import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LandAdmin } from '@app/shared/classes/land';
import { BackendService } from '@app/shared/services/backend.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-admin-stammdaten-land-entry]',
  templateUrl: './admin-stammdaten-land-entry.component.html'
})
export class AdminStammdatenLandEntryComponent {
  @Output() deleted = new EventEmitter();

  landForm: UntypedFormGroup;
  isLoading = false;
  delChosen = false;

  land: LandAdmin;

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(land: LandAdmin) {
    this.land = land;
    this.initForm(land);
  }

  private initForm(land: LandAdmin) {
    this.landForm = this.formBuilder.group({
      id: [''],
      bezeichnungDe: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required],
      sortierung: ['', [Validators.pattern('^[0-9]*$'), Validators.required]],
      zulassungsLand: [''],
      importLand: [''],
      nutztier: [''],
      heimtier: ['']
    });
    this.updateForm(land);
  }

  private updateForm(land: LandAdmin) {
    this.landForm.reset({
      id: land.id,
      bezeichnungDe: land.bezeichnungDe,
      bezeichnungFr: land.bezeichnungFr,
      bezeichnungIt: land.bezeichnungIt,
      sortierung: land.sortierung,
      zulassungsLand: land.zulassungsLand,
      importLand: land.importLand,
      nutztier: land.nutztierErlaubt,
      heimtier: land.heimtierErlaubt
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.landForm.value;
    this.land.id = formValues.id;
    this.land.bezeichnungDe = formValues.bezeichnungDe;
    this.land.bezeichnungFr = formValues.bezeichnungFr;
    this.land.bezeichnungIt = formValues.bezeichnungIt;
    this.land.sortierung = formValues.sortierung;
    this.land.zulassungsLand = formValues.zulassungsLand;
    this.land.importLand = formValues.importLand;
    this.land.nutztierErlaubt = formValues.nutztier;
    this.land.heimtierErlaubt = formValues.heimtier;
    this.backendService.editLand(this.land)
      .subscribe({
        next: result => {
          this.land = result;
          this.reloadEntries.emit();
        },
        error: console.error
      });
  }

  onReset() {
    this.updateForm(this.land);
  }

  deleteLand(landId: number) {
    this.delChosen = true;
    Swal.fire({
      text: this.translateService.instant('admin.land.delete.warning'),
      icon: 'warning',
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('admin.land.delete.yes'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.continueDeleteLand(landId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteLand(landId: number) {
    this.backendService.deleteLand(landId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.error(error.message);
        }
      });
  }

  showAlert(ok: boolean) {
    let message = this.translateService.instant('admin.land.delete.success');
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = this.translateService.instant('admin.land.delete.error');
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
