export interface PackungJSON {
  anzahl: number;
  groesse: string;
}

export class Packung {
  anzahl: number;
  groesse: string;

  static fromJSON(json: string | object): Packung {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const packung = Object.create(Packung.prototype);
    return Object.assign(packung, object);
  }

  toJSON(): PackungJSON {
    return Object.assign({}, this);
  }
}
