<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">{{ 'admin.error' | translate }}</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <div class="row admin">
      <p class="admin-warning"><b>{{ 'admin.warning' | translate }}</b></p>
    </div>
    <app-admin-stammdaten-importgrund-new (reloadEntries)="reloadEntries()"></app-admin-stammdaten-importgrund-new>
    <div class="row admin">
      <div class="col-md-1.5 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.de' | translate }}</b></p></div>
      <div class="col-md-1.5 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.fr' | translate }}</b></p></div>
      <div class="col-md-1.5 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.it' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.sort' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.kommentar' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.zulassungsNrCh' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.lieferEngpass' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.importgrund.column.aktiv' | translate }}</b></p></div>
      <div class="col-md-2.5 pl-0 mb-0"></div>
    </div>
    <div class="row admin" *ngFor="let set of gruende; let i = index"
         app-admin-stammdaten-importgrund-entry
         [set]="set"
         (reloadEntries)="reloadEntries()"
         (deleted)="onGrundDeleted(i)">
    </div>
  </ng-template>
</div>
