<div class="container-fluid" *ngIf="meldung">
  <!-- Actionbar -->
  <div class="row">
    <div class="col-sm-6">
      <a routerLink=".." class="btn btn-default" role="button"
         [matTooltip]="'global.tooltip.home' | translate"
         matTooltipPosition="right">
        <span class="glyphicon glyphicon-home"></span>
      </a>
      <a routerLink="../new" [queryParams]="{copy: meldung.id}" *appIsTierarzt
         class="btn btn-default ml-15" role="button">
        {{ "meldung.new.link.kopie" | translate }}
      </a>
      <button (click)="deactivateMeldung()"
              class="btn btn-default ml-15" role="button" [disabled]="stornoDisabled">
        {{ "meldung.view.deaktivieren.label" | translate }}
      </button>
      <button (click)="bewilligeMeldung()"
              class="btn btn-default ml-15" role="button" *ngIf="showBewilligeMeldung">
        {{ "meldung.view.bewilligen.label" | translate }}
      </button>
      <button (click)="editNotiz()"
              class="btn btn-default ml-15" role="button" *appIsBlv>
        {{ "meldung.view.notiz.label" | translate }}
      </button>
    </div>
    <div class="col-sm-6" *ngIf="!isStorniert">
      <a (click)="downloadPdf()" *ngIf="!isExtendedPdf"
         class="btn btn-default pull-right" role="button">
        {{ "meldung.generate.pdf" | translate }}
      </a>
      <span class="dropdown pull-right" appDropdown *ngIf="isExtendedPdf">
        <button class="btn btn-default pull-right dropdown-toggle"
                role="button" type="button" data-toggle="dropdown">
          {{ "meldung.generate.pdf" | translate }}
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="cursor-pointer" (click)="downloadPdf()">{{ "templateType.bewilligungs_antrag" | translate }}</a>
          </li>
          <li>
            <a class="cursor-pointer" (click)="downloadBewilligung()">{{ "templateType.bewilligung" | translate }}</a>
          </li>
        </ul>
      </span>
    </div>
  </div>
  <!-- Banner -->
  <div *ngIf="showNotizBlv" class="alert alert-info" role="alert">
    <span class="sr-only">info:</span>
    <strong *ngIf="meldung.notiz.offeneAnfrage">{{ "meldung.view.notiz.offeneAnfrage" | translate }}</strong>
    <br *ngIf="meldung.notiz.offeneAnfrage && meldung.notiz.notizBlv"/>
    <span class="line-break" *ngIf="meldung.notiz.notizBlv">{{ meldung.notiz.notizBlv }}</span>
  </div>
  <div *ngIf="isStornoAnfrage" class="alert alert-info" role="alert">
    <span class="sr-only">info:</span><strong>{{ "meldung.view.deaktivieren.request" | translate }}</strong><br/>
    <span class="line-break">{{ meldung.stornoKommentar }}</span>
  </div>
  <div *ngIf="isStorniert" class="alert alert-warning" role="alert">
    <span class="sr-only">warning:</span><strong>{{ "meldung.view.deaktivieren.success" | translate }}</strong><br/>
    <span class="line-break">{{ meldung.stornoKommentar }}</span>
  </div>
  <div *ngIf="showCreatedMeldung && meldung?.requiredDokumente?.bewilligungsPflichtig" class="alert alert-warning" role="alert">
    <span class="sr-only">info:</span><span [innerHTML]="'meldung.view.gespeichert.pflichtig' | translate | marked"></span>
  </div>
  <div *ngIf="showCreatedMeldung && !meldung?.requiredDokumente?.bewilligungsPflichtig" class="alert alert-success" role="alert">
    <span class="sr-only">info:</span><span [innerHTML]="'meldung.view.gespeichert.frei' | translate | marked"></span>
  </div>
  <!-- Dokumente -->
  <dokument [id]="meldung.id" (preventRedirect)="preventRedirect = $event"></dokument>
  <!-- Meldung -->
  <div class="row">
    <div class="col-sm-12">
      <fieldset>
        <legend>
          <ng-template [ngIf]="meldung.requiredDokumente?.bewilligungsPflichtig">
            <h1 [innerText]="'meldung.view.title.pflichtig.antrag' | translate" *ngIf="!meldung.requiredDokumente?.bewilligungVorhanden"></h1>
            <h1 [innerText]="'meldung.view.title.pflichtig.bewilligt' | translate" *ngIf="meldung.requiredDokumente?.bewilligungVorhanden"></h1>
          </ng-template>
          <h1 [innerText]="'meldung.view.title.frei' | translate" *ngIf="!meldung.requiredDokumente?.bewilligungsPflichtig"></h1>
        </legend>
        <label class="col-sm-2">
          {{ "meldung.feld.erfDate" | translate }}
        </label>
        <div class="col-sm-4">
          {{ meldung.erfDate | date:'dd.MM.yyyy HH:mm' }}
        </div>
        <label class="col-sm-2" [innerText]="'meldung.feld.nummer.pflichtig' | translate" *ngIf="meldung.requiredDokumente?.bewilligungsPflichtig"></label>
        <label class="col-sm-2" [innerText]="'meldung.feld.nummer.frei' | translate" *ngIf="!meldung.requiredDokumente?.bewilligungsPflichtig"></label>
        <div class="col-sm-4">
          {{ meldung.nummer }}
        </div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <fieldset>
        <legend>
          {{ "meldung.bereich.identifikation" | translate }}
        </legend>
        <label class="col-sm-2">
          {{ "meldung.feld.uid" | translate }}
        </label>
        <div class="col-sm-4">
          {{ meldung.uid }}
        </div>
        <label class="col-sm-2">
          {{ "meldung.feld.burNr" | translate }}
        </label>
        <div class="col-sm-4">
          {{ meldung.burNr }}
        </div>
        <label class="col-sm-2">
          {{ "meldung.feld.praxis" | translate }}
        </label>
        <div class="col-sm-4 line-break">{{ meldung.praxis }}</div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <fieldset>
        <legend>
          {{ "meldung.bereich.bestellendePerson" | translate }}
        </legend>
        <label class="col-sm-2">
          {{ "meldung.feld.vorname" | translate }}
        </label>
        <div class="col-sm-4">
          {{ meldung.vorname }}
        </div>
        <label class="col-sm-2">
          {{ "meldung.feld.nachname" | translate }}
        </label>
        <div class="col-sm-4">
          {{ meldung.nachname }}
        </div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <fieldset>
        <legend>
          {{ "meldung.bereich.kontaktangaben" | translate }}
        </legend>
        <label class="col-sm-2">
          {{ "meldung.feld.email" | translate }}
        </label>
        <div class="col-sm-4">
          <a href="mailto:{{ meldung.email }}">
            {{ meldung.email }}
          </a>
        </div>
        <label class="col-sm-2">
          {{ "meldung.feld.telefon" | translate }}
        </label>
        <div class="col-sm-4">
          {{ meldung.telefon }}
        </div>
      </fieldset>
    </div>
  </div>
  <fieldset>
    <legend>
      {{ "meldung.bereich.importAngaben" | translate }}
    </legend>
    <div class="row">
      <div class="col-sm-12 mb-0">
        <label class="col-sm-2">
          {{ "meldung.feld.grund" | translate }}
        </label>
        <div class="col-sm-10">
          {{ meldung.grund.bezeichnung }}
        </div>
        <label class="col-sm-2">
          {{ "meldung.feld.grundKommentar" | translate }}
        </label>
        <div class="col-sm-4 line-break">{{ meldung.grundKommentar }}</div>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>
      {{ "meldung.bereich.praeparat" | translate }}
    </legend>
    <div class="row">
      <div class="col-sm-6">
        <label class="col-sm-4">
          {{ "meldung.feld.praeparat.name" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.praeparat.name }}
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-4">
          {{ "meldung.feld.praeparat.nutzung" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.praeparat.nutzung.messageProperty | translate }}
        </div>
      </div>
      <div class="col-sm-6" *ngIf="meldung.art.id > 10">
        <label class="col-sm-4">
          {{ "meldung.feld.praeparat.spezies" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.praeparat.spezies }}
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-4">
          {{ "meldung.feld.praeparat.zulassungsNr" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.praeparat.zulassungsNr }}
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-4">
          {{ "meldung.feld.praeparat.zulassungsLand" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.praeparat.zulassungsLand.bezeichnung }}
        </div>
      </div>
      <div class="col-sm-6" *ngIf="meldung.grund.zulassungsNrChAnzeigen">
        <label class="col-sm-4">
          {{ "meldung.feld.praeparat.zulassungsNrCh" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.praeparat.zulassungsNrCh }}
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-4">
          {{ "meldung.feld.importLand" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.importLand.bezeichnung }}
        </div>
      </div>
      <div class="col-sm-6">
        <label class="col-sm-4">
          {{ "meldung.feld.haendlerAusland" | translate }}
        </label>
        <div class="col-sm-8 mb-0">
          {{ meldung.haendlerAusland }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 mb-0">
        <label class="col-sm-4 control-label"
               [innerHTML]="'meldung.feld.praeparat.anhang5' | translate | injectUrl:'url.anhang5'">
        </label>
        <div class="col-sm-8">
          <div class="radio radio--left">
            <label for="anhang5True">
              <input disabled type="radio" id="anhang5True" [value]="true"
                     [(ngModel)]="meldung.praeparat.anhang5">
              {{ "global.yes" | translate }}
            </label>
          </div>
          <div class="radio radio--left">
            <label for="anhang5False">
              <input disabled type="radio" id="anhang5False" [value]="false"
                     [(ngModel)]="meldung.praeparat.anhang5">
              {{ "global.no" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-0" *ngIf="meldung.art.id > 10">
        <label class="col-sm-4 control-label">
          {{ "meldung.feld.praeparat.umgewidmet" | translate }}
        </label>
        <div class="col-sm-8">
          <div class="radio radio--left">
            <label for="umgewidmetTrue">
              <input disabled type="radio" id="umgewidmetTrue" [value]="true"
                     [(ngModel)]="meldung.praeparat.umgewidmet">
              {{ "global.yes" | translate }}
            </label>
          </div>
          <div class="radio radio--left">
            <label for="umgewidmetFalse">
              <input disabled type="radio" id="umgewidmetFalse" [value]="false"
                     [(ngModel)]="meldung.praeparat.umgewidmet">
              {{ "global.no" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-0" *ngIf="meldung.art.id <= 10">
        <label class="col-sm-4 control-label">
          {{ "meldung.feld.praeparat.auslaendischeZulassung" | translate }}
        </label>
        <div class="col-sm-8">
          <div class="checkbox checkbox--left">
            <label for="auslaendischeZulassungTierart">
              <input disabled type="checkbox" id="auslaendischeZulassungTierart" [value]="true"
                     [(ngModel)]="meldung.praeparat.auslaendischeZulassungTierart">
              {{ "meldung.feld.praeparat.auslaendischeZulassung.tierart" | translate }}
            </label>
          </div>
          <div class="checkbox checkbox--left" *ngIf="showAuslaendischeZulassungIndikation">
            <label for="auslaendischeZulassungIndikation">
              <input disabled type="checkbox" id="auslaendischeZulassungIndikation" [value]="true"
                     [(ngModel)]="meldung.praeparat.auslaendischeZulassungIndikation">
              {{ "meldung.feld.praeparat.auslaendischeZulassung.indikation" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-0">
        <label class="col-sm-4 control-label">
          {{ "meldung.feld.praeparat.bienen" | translate }}
        </label>
        <div class="col-sm-8">
          <div class="radio radio--left">
            <label for="bienenTrue">
              <input disabled type="radio" id="bienenTrue" [value]="true"
                     [(ngModel)]="meldung.praeparat.bienen">
              {{ "global.yes" | translate }}
            </label>
          </div>
          <div class="radio radio--left">
            <label for="bienenFalse">
              <input disabled type="radio" id="bienenFalse" [value]="false"
                     [(ngModel)]="meldung.praeparat.bienen">
              {{ "global.no" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-0" *ngIf="meldung.art?.id < MeldungArtId.GAMMA">
        <label class="col-sm-4 control-label">
          {{ "meldung.feld.praeparat.betaeubungsmittel" | translate }}
        </label>
        <div class="col-sm-8">
          <div class="radio radio--left">
            <label for="betaeubungsmittelTrue">
              <input disabled type="radio" id="betaeubungsmittelTrue" [value]="true"
                     [(ngModel)]="meldung.praeparat.betaeubungsmittel">
              {{ "global.yes" | translate }}
            </label>
          </div>
          <div class="radio radio--left">
            <label for="betaeubungsmittelFalse">
              <input disabled type="radio" id="betaeubungsmittelFalse" [value]="false"
                     [(ngModel)]="meldung.praeparat.betaeubungsmittel">
              {{ "global.no" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-0">
        <label class="col-sm-4 control-label">
          {{ "meldung.feld.praeparat.immunologisch" | translate }}
        </label>
        <div class="col-sm-8">
          <div class="radio radio--left">
            <label for="immunologischTrue">
              <input disabled type="radio" id="immunologischTrue" [value]="true"
                     [(ngModel)]="meldung.praeparat.immunologisch">
              {{ "global.yes" | translate }}
            </label>
          </div>
          <div class="radio radio--left">
            <label for="immunologischFalse">
              <input disabled type="radio" id="immunologischFalse" [value]="false"
                     [(ngModel)]="meldung.praeparat.immunologisch">
              {{ "global.no" | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-0" *ngIf="showImmunologischFelder">
        <div class="row">
          <div class="col-sm-12 mb-15">
            <label class="col-sm-4 mb-0">
              {{ "meldung.feld.praeparat.hersteller.kurz" | translate }}
            </label>
            <div class="col-sm-8 mb-0">
              {{ meldung.praeparat.hersteller }}
            </div>
          </div>
          <div class="col-sm-12 mb-15">
            <label class="col-sm-4 mb-0">
              {{ "meldung.feld.praeparat.bestandteile.kurz" | translate }}
            </label>
            <div class="col-sm-8 mb-0">
              {{ meldung.praeparat.bestandteile }}
            </div>
          </div>
          <div class="col-sm-12 mb-15">
            <label class="col-sm-4 mb-0">
              {{ "meldung.feld.praeparat.tierhalter.kurz" | translate }}
            </label>
            <div class="col-sm-8 mb-0">
              {{ meldung.praeparat.tierhalter }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-offset-2 col-sm-6">
        <table class="table">
          <thead>
          <tr>
            <th>{{ "meldung.feld.praeparat.packungen.groesse" | translate }}</th>
            <th>{{ "meldung.feld.praeparat.packungen.anzahl" | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let packung of meldung.praeparat.packungen">
            <td>{{ packung.groesse }}</td>
            <td>{{ packung.anzahl }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="row">
      <div class="col-sm-12">
        <label class="col-sm-2">
          {{ "meldung.feld.hauptindikation" | translate }}
        </label>
        <div class="col-sm-4 line-break">{{ meldung.hauptIndikation }}</div>
        <label class="col-sm-2">
          {{ "meldung.feld.kommentar" | translate }}
        </label>
        <div class="col-sm-4 line-break">{{ meldung.kommentar }}</div>
      </div>
    </div>
  </fieldset>
  <div class="row" *ngIf="showAlertBewilligungsPflichtig">
    <div class="col-sm-offset-2 col-sm-8 mb-0">
      <div class="alert alert-warning" [innerHTML]="'meldung.feld.praeparat.alert.bewilligungspflichtig' | translate | marked"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <div class="col-sm-8 col-sm-offset-2">
          <div class="checkbox checkbox--left">
            <label for="checkbox5">
              <input disabled checked type="checkbox" id="checkbox5" value="checkbox5">
              <span class="line-break" [innerHTML]="bestaetigungCode | translate | injectUrl:'url.bestaetigung'"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="hidden">
  <div #notizEdit>
    <notiz-edit [notiz]="meldung?.notiz" (result)="showMeldung($event)" *appIsBlv></notiz-edit>
  </div>

  <div #deaktivierenDialog>
    <meldung-deaktivieren-dialog [meldung]="meldung" (result)="showMeldung($event)"></meldung-deaktivieren-dialog>
  </div>

  <div #bewilligenDialog *ngIf="showBewilligeMeldung">
    <meldung-bewilligen-dialog [meldung]="meldung" (result)="showMeldung($event)"></meldung-bewilligen-dialog>
  </div>
</div>
