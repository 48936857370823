import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MeldungBewilligungFilter } from "@app/shared/classes/meldung-bewilligung-filter";

@Component({
  selector: 'meldung-list-filter',
  templateUrl: './meldung-list-filter.component.html'
})
export class MeldungListFilterComponent {

  filter: MeldungBewilligungFilter = new MeldungBewilligungFilter();

  readonly ICON_CLASSES = {
    default : 'cursor-pointer glyphicon glyphicon-unchecked     gray',
    1       : 'cursor-pointer glyphicon glyphicon-ok-circle     green',
    2       : 'cursor-pointer glyphicon glyphicon-remove-circle red'
  };

  @Output() bewilligungChange: EventEmitter<MeldungBewilligungFilter> = new EventEmitter<MeldungBewilligungFilter>();
  @Input() set bewilligung(value: MeldungBewilligungFilter) {
    this.filter = value || new MeldungBewilligungFilter();
  }

  getIcon(property: string): string {
    let value = this.filter[property];
    return this.ICON_CLASSES[value] || this.ICON_CLASSES.default;
  }

  toggleIcon(property: string): void {
    let value = this.filter[property];
    if (value === 1) {
      this.filter[property] = 2;
    } else if (value === 2) {
      this.filter[property] = null;
    } else {
      this.filter[property] = 1;
    }
    this.bewilligungChange.emit(this.filter);
  }
}
