import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from '@app/core/auth/login';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { LoginService } from '@app/shared/services/login.service';
import Keycloak from 'keycloak-js';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private keycloak: Keycloak;
  private tokenTimer: NodeJS.Timeout;
  private authenticatedSubject = new BehaviorSubject<boolean>(false);
  private user: Login;
  public onTokenExpired: () => void;

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService,
              private loginService: LoginService,
              private router: Router) {
  }

  get authenticated$(): Observable<boolean> {
    return this.authenticatedSubject;
  }

  isAuthenticated(): boolean {
    return this.authenticatedSubject.getValue();
  }

  async init(): Promise<void> {
    const config = this.configurationService.getConfig();

    const keycloakConfig: Keycloak.KeycloakConfig = {
      url: config.ssoUrl,
      realm: config.ssoRealm,
      clientId: config.ssoClient
    };

    // Login via eIAM -> BLV-Mitarbeiter

    const initOptions: Keycloak.KeycloakInitOptions = {
      pkceMethod: 'S256',
      onLoad: 'login-required',
      checkLoginIframe: false,
      refreshToken: localStorage.getItem('refresh_token'),
      idToken: localStorage.getItem('id_token'),
      token: localStorage.getItem('access_token')
    };

    if (this.isISAbv()) {
      let token = this.getISAbvToken();
      let username = this.extractUsernameFromToken(token);

      let json = await fetch(`${config.ssoUrl}/realms/${config.ssoRealm}/protocol/openid-connect/token`, {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          'grant_type': 'password',
          'username': username,
          'password': token,
          'client_id': config.ssoClient,
          'scope': 'openid'
        })
      }).then(response => response.json());

      initOptions.refreshToken = json.refresh_token;
      initOptions.idToken = json.id_token;
      initOptions.token = json.access_token;
    }

    this.keycloak = new Keycloak(keycloakConfig);
    this.keycloak.onAuthSuccess = () => this.storeAuthorization();
    this.keycloak.onAuthRefreshSuccess = () => this.startTokenTimer();
    this.keycloak.onTokenExpired = () => this.tokenExpired();
    this.keycloak.onAuthLogout = () => {
      this.clearAuthorization();
      this.authenticatedSubject.next(false);
    };

    // Keycloak hinterlegen fürs Backend-/Frontend-Test
    globalThis.keycloak = this.keycloak;

    const authenticated = await this.keycloak.init(initOptions);
    if (authenticated) {
      await this.initUser();
    }

    // LocalStorage Elemente löschen, die nicht nach einem Refresh erhalten bleiben sollen
    this.clearLocalStorage();
  }

  isISAbv() {
    return location.pathname.startsWith('/extern');
  }

  getISAbvToken(): string {
    // TODO Besser mit Angular API umsetzen
    let regex = /^\?jwt=(?<token>.*)$/;
    let result = regex.exec(location.search)
    if (!result) {
      regex = /^\/extern\/(?<token>.*)$/;
      result = regex.exec(location.pathname);
    }
    return result?.groups.token;
  }

  extractUsernameFromToken(token: string): string {
    //TODO Fehlerhandling
    let regex = /^(?<header>[\w-]+)\.(?<payload>[\w-]+)\.(?<signature>[\w-]+$)/
    let payload = regex.exec(token).groups.payload;
    let base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    let json = this.unicode_atob(base64);
    let data = JSON.parse(json);
    return JSON.stringify({sub: data.sub});
  }

  private unicode_atob(base64: string): string {
    // Bugfix: atob kann nicht mit Base64 encoded Unicode-Strings umgehen
    //         https://stackoverflow.com/a/62537645/11291109
    return decodeURIComponent(escape(atob(base64))); // NOSONAR (siehe oben)
  }

  private async initUser(): Promise<void> {
    const token = this.keycloak.idTokenParsed as any;

    // Sprache auslesen
    let sprache = token.lang;
    // Wenn die Sprache nicht im Token geliefert wird, nehmen wir die Browsersprache
    if(sprache == undefined) {
      sprache = navigator.language;
    }

    const user: Login = {
      userId: token.preferred_username,
      vorname: token.given_name,
      nachname: token.family_name,
      email: token.email,
      anschrift: token.address,
      uid: token.uid,
      burNr: token.bur,
      language: sprache
    };

    const response = await firstValueFrom(this.loginService.get(user));

    // Wenn der Benutzer undefined ist oder die Inhaltsrolle nicht erhält,
    // wird er auf die Zugriffsantragsseite weitergeleitet
    if (response.user === undefined) {
      const config = this.configurationService.getConfig();
      // Keycloak Logout durchführen
      await fetch(`${config.ssoUrl}/realms/${config.ssoRealm}/protocol/openid-connect/logout`)
        .then(response => console.log('Keycloak logout successful?', response.ok));

      // Weiterleiten
      window.location.href = config.ssoAccessReqUrl.replace('${ssoClient}', config.ssoClient).replace('${returnURL}', config.rootUrl);
    } else if (this.isISAbv() && response.user.language !== sprache) {
      // Beim ISABV-Login die Sprache aus dem Token übernehmen
      response.user.language = sprache;
      this.user = await firstValueFrom(this.loginService.changeLanguage(response.user));
      this.user.roles = response.roles;
      this.authenticatedSubject.next(true);
    } else {
      this.user = response.user;
      this.user.roles = response.roles;
      this.authenticatedSubject.next(true);
    }
  }

  checkLoginState(): Promise<boolean> {
    if (!this.keycloak.authenticated) {
      return Promise.resolve(false);
    }
    return this.refreshToken()
      .then(() => this.keycloak.authenticated);
  }

  getAccessToken(): Promise<string> {
    return this.refreshToken()
      .then(() => this.keycloak.token);
  }

  refreshToken(minValidity = 10): Promise<boolean> {
    return this.keycloak.updateToken(minValidity);
  }

  getUser() {
    return this.user;
  }

  getProfile(): Keycloak.KeycloakProfile {
    return this.keycloak.profile;
  }

  logout(): Promise<void> {
    this.clearAuthorization();
    return this.keycloak.logout();
  }

  goToMyAccount(): void {
    const config = this.configurationService.getConfig();
    const url = config.ssoAccountUrl.replace('${returnURL}', encodeURIComponent(location.href));
    window.open(url, '_blank');
  }

  private clearLocalStorage() {
    localStorage.removeItem('filter');
  }

  private storeAuthorization() {
    localStorage.setItem('access_token', this.keycloak.token);
    localStorage.setItem('refresh_token', this.keycloak.refreshToken);
    localStorage.setItem('id_token', this.keycloak.idToken);

    this.startTokenTimer();
  }

  private clearAuthorization() {
    this.user = null;
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('id_token');
    this.keycloak.clearToken();
    this.clearTokenTimer();
  }

  private startTokenTimer(): void {
    this.clearTokenTimer();

    const issuedAt = this.keycloak.tokenParsed.iat;
    const expiresAt = this.keycloak.tokenParsed.exp
    const expirationTimeout = expiresAt - issuedAt;

    // 20 Sekunden vor Ablauf das Token erneuern
    const nextRefresh = Math.max(0, expirationTimeout - 20);
    this.tokenTimer = setTimeout(() => this.refreshToken(20), nextRefresh * 1000);
  }

  private clearTokenTimer(): void {
    if (this.tokenTimer) {
      clearTimeout(this.tokenTimer);
    }
    this.tokenTimer = null;
  }

  private tokenExpired(): void {
    if (this.onTokenExpired) {
      this.onTokenExpired();
    }
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.checkLoginState()
      .then( authenticated => authenticated || this.generateUrlTree(route, '/unauthorized') );
  }

  generateUrlTree(route: ActivatedRouteSnapshot, url: string): UrlTree {
    return this.router.createUrlTree([url], {
      queryParams: {
        url: this.router.serializeUrl(
          this.router.createUrlTree(
            route.pathFromRoot
              .reduce((segments, current) => segments.concat(current.url), [])
              .map(value => value.toString()),
            {
              queryParams: route.queryParams,
              fragment: route.fragment
            }
          )
        )
      }
    });
  }
}
