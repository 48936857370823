import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { SettingsService } from "@app/core/config/settings.service";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'injectUrl',
  pure: false
})
export class InjectUrlPipe implements PipeTransform {

  msg: string;
  result: string;

  constructor(private settingService: SettingsService,
              private translate: TranslateService,
              private sanitizer: DomSanitizer) {
  }

  transform(msg: string, ...urlKeys: string[]): any  {
    if (this.msg === msg) return this.result;

    let suffix = '.' + this.translate.currentLang;
    let urls = urlKeys.map((key: string) => this.settingService.get(key + suffix));
    let result = this.injectLinks(msg, urls);

    this.msg = msg;
    this.result = result;

    return result;
  }

  injectLinks(msg: string, urls: string[]): string {
    // sanitize strings
    msg = this.sanitizer.sanitize(SecurityContext.HTML, msg);
    urls = urls.map(url => this.sanitizer.sanitize(SecurityContext.URL, url));

    let result = [];
    let closeLink = false;
    msg.split(/\|/).forEach( (str, index) => {
      if (closeLink) {
        closeLink = false;
        result.push('</a>');
      } else if (index > 0 && urls.length) {
        closeLink = true;
        result.push('<a href="');
        result.push(urls.shift());
        result.push('" target="_blank" class="icon icon--after icon--external">');
      }
      result.push(str);
    });
    if (closeLink) result.push('</a>');
    return result.join('');
  }
}
