import {Pipe, PipeTransform} from '@angular/core';
import { marked } from "marked";

@Pipe({
  name: 'marked'
})
export class MarkedPipe implements PipeTransform {

  transform(value: string, inline?: boolean): any  {
    if (value && value.length > 0) {
      return inline ? marked.parseInline(value) : marked(value);
    }
    return value;
  }
}
