import {Pipe, PipeTransform} from '@angular/core';
import { SettingsService } from "@app/core/config/settings.service";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: 'setting',
  pure: false
})
export class SettingPipe implements PipeTransform {

  constructor(private settingService: SettingsService,
              private translate: TranslateService) {
  }

  transform(key: string, addLanguageSuffix?: boolean): any  {
    if (addLanguageSuffix) {
      key += '.' + this.translate.currentLang;
    }

    return this.settingService.get(key);
  }
}
