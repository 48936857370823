import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LandAdmin } from '@app/shared/classes/land';
import { BackendService } from '@app/shared/services/backend.service';
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-admin-stammdaten-land-new',
  templateUrl: './admin-stammdaten-land-new.component.html'
})
export class AdminStammdatenLandNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();

  newLandForm: UntypedFormGroup;
  isLoading = false;

  land: LandAdmin = new LandAdmin();

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.newLandForm = this.formBuilder.group({
      bezeichnungDe_new: ['', Validators.required],
      bezeichnungFr_new: ['', Validators.required],
      bezeichnungIt_new: ['', Validators.required],
      sortierung_new: ['', [Validators.pattern('^[0-9]*$'), Validators.required]],
      zulassungsLand_new: [''],
      importLand_new: [''],
      nutztier_new: [''],
      heimtier_new: ['']
    });
  }

  onSubmit() {
    const formValues = this.newLandForm.value;
    this.land.bezeichnungDe = formValues.bezeichnungDe_new;
    this.land.bezeichnungFr = formValues.bezeichnungFr_new;
    this.land.bezeichnungIt = formValues.bezeichnungIt_new;
    this.land.sortierung = formValues.sortierung_new;
    this.land.zulassungsLand = formValues.zulassungsLand_new;
    this.land.importLand = formValues.importLand_new;
    this.land.nutztierErlaubt = formValues.nutztier_new;
    this.land.heimtierErlaubt = formValues.heimtier_new;
    this.backendService.createLand(this.land)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          this.showAlert();
          console.error(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  showAlert() {
    Swal.fire({
      text: this.translateService.instant('admin.land.save.error'),
      icon: 'error',
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
