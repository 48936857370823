import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { SettingsService } from '@app/core/config/settings.service';

export function initializeApp(appInitService: AppInitService) {
  // a lambda is required here, otherwise `this` won't work inside ConfigurationService::load
  return () => appInitService.init();
}

@Injectable()
export class AppInitService {

  constructor(private configurationService: ConfigurationService,
              private authorizationService: AuthenticationService,
              private settingsService: SettingsService) {
  }

  async init(): Promise<any> {
    await this.configurationService.load();
    await this.authorizationService.init();
    await this.settingsService.initSettings();
  }
}
