import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appCollapseContent]',
  exportAs: 'appCollapseContent'
})
export class CollapseContentDirective {

  @HostBinding('class.show')
  showing = false;
}
