<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">{{ 'admin.error' | translate }}</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <div class="row admin">
      <p class="admin-warning"><b>{{ 'admin.warning' | translate }}</b></p>
    </div>
    <app-admin-stammdaten-deaktivierungsgrund-new (reloadEntries)="reloadEntries()"></app-admin-stammdaten-deaktivierungsgrund-new>
    <div class="row admin">
      <div class="col-sm-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.deaktivierungsgrund.column.sort' | translate }}</b></p></div>
      <div class="col-sm-3 pl-0 mb-0"><p class="admin"><b>{{ 'global.sprache.de' | translate }}</b></p></div>
      <div class="col-sm-3 pl-0 mb-0"><p class="admin"><b>{{ 'global.sprache.fr' | translate }}</b></p></div>
      <div class="col-sm-3 pl-0 mb-0"><p class="admin"><b>{{ 'global.sprache.it' | translate }}</b></p></div>
      <div class="col-sm-2 pl-0 mb-0"></div>
    </div>
    <app-admin-stammdaten-deaktivierungsgrund-entry *ngFor="let set of gruende; let i = index"
      [set]="set" (reloadEntries)="reloadEntries()" (deleted)="onGrundDeleted(i)">
    </app-admin-stammdaten-deaktivierungsgrund-entry>
  </ng-template>
</div>
