export interface DeaktivierungsGrundJSON {
  id: number;
  bezeichnung: string;
  text: string;
}

export class DeaktivierungsGrund {
  id: number;
  bezeichnung: string;
  text: string;

  static fromJSON(json: string | object): DeaktivierungsGrund {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const grund = Object.create(DeaktivierungsGrund.prototype);
    return Object.assign(grund, object);
  }

  toJSON(): DeaktivierungsGrundJSON {
    return Object.assign({}, this);
  }
}

export interface DeaktivierungsGrundAdminJSON {
  id?: number;
  bezeichnungDe: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  textDe: string;
  textFr: string;
  textIt: string;
  sortierung: number;
}

export class DeaktivierungsGrundAdmin {
  id?: number;
  bezeichnungDe: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  textDe: string;
  textFr: string;
  textIt: string;
  sortierung: number;

  static fromJSON(json: string | object): DeaktivierungsGrundAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const grund = Object.create(DeaktivierungsGrundAdmin.prototype);
    return Object.assign(grund, object);
  }

  toJSON(): DeaktivierungsGrundAdminJSON {
    return Object.assign({}, this);
  }
}
