import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImportGrundAdmin } from "@app/shared/classes/import-grund";
import { BackendService } from '@app/shared/services/backend.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-admin-stammdaten-importgrund-entry]',
  templateUrl: './admin-stammdaten-importgrund-entry.component.html'
})
export class AdminStammdatenImportgrundEntryComponent {
  @Output() deleted = new EventEmitter();

  grundForm: UntypedFormGroup;
  isLoading = false;
  delChosen = false;

  grund: ImportGrundAdmin;

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(grund: ImportGrundAdmin) {
    this.grund = grund;
    this.initForm(grund);
  }

  private initForm(grund: ImportGrundAdmin) {
    this.grundForm = this.formBuilder.group({
      id: ['', Validators.required],
      bezeichnungDe: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required],
      sortierung: ['', Validators.required],
      kommentarPflicht: [''],
      zulassungsNrChAnzeigen: [''],
      lieferEngpassAnzeigen: [''],
      aktiv: ['']
    });
    this.updateForm(grund);
  }

  private updateForm(grund: ImportGrundAdmin) {
    this.grundForm.reset({
      id: grund.id,
      bezeichnungDe: grund.bezeichnungDe,
      bezeichnungFr: grund.bezeichnungFr,
      bezeichnungIt: grund.bezeichnungIt,
      sortierung: grund.sortierung,
      kommentarPflicht: grund.kommentarPflicht,
      zulassungsNrChAnzeigen: grund.zulassungsNrChAnzeigen,
      lieferEngpassAnzeigen: grund.lieferEngpassAnzeigen,
      aktiv: grund.aktiv
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.grundForm.value;
    this.grund.id = formValues.id;
    this.grund.bezeichnungDe = formValues.bezeichnungDe;
    this.grund.bezeichnungFr = formValues.bezeichnungFr;
    this.grund.bezeichnungIt = formValues.bezeichnungIt;
    this.grund.sortierung = formValues.sortierung;
    this.grund.kommentarPflicht = formValues.kommentarPflicht;
    this.grund.zulassungsNrChAnzeigen = formValues.zulassungsNrChAnzeigen;
    this.grund.lieferEngpassAnzeigen = formValues.lieferEngpassAnzeigen;
    this.grund.aktiv = formValues.aktiv;
    this.backendService.editImportgrund(this.grund)
      .subscribe({
        next: result => {
          this.grund = result;
          this.reloadEntries.emit();
        },
        error: console.error
      });
  }

  onReset() {
    this.updateForm(this.grund);
  }

  deleteGrund(grundId: number) {
    this.delChosen = true;
    Swal.fire({
      text: this.translateService.instant('admin.importgrund.delete.warning'),
      icon: 'warning',
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('admin.importgrund.delete.yes'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.continueDeleteGrund(grundId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteGrund(grundId: number) {
    this.backendService.deleteImportgrund(grundId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.error(error.message);
        }
      });
  }

  showAlert(ok: boolean) {
    let message = this.translateService.instant('admin.importgrund.delete.success');
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = this.translateService.instant('admin.importgrund.delete.error');
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
