import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filesize',
  pure: true
})
export class FilesizePipe implements PipeTransform {

  transform(value: any, args?: any): any  {
    if (value === 0) { return '0 Bytes'; }

    const k = 1024;
    const dm = args < 0 ? 0 : args;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(value) / Math.log(k));

    return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
