import { MeldungBewilligungFilter } from "@app/shared/classes/meldung-bewilligung-filter";

export interface MeldungFilterJSON {
  sortColumn: string;
  sortOrder: string;

  freitext: string;
  land: number;
  status: number;
  offeneAnfrage: number;
  datumVon: string;
  datumBis: string;

  limit: number;
  page: number;

  bewilligung: MeldungBewilligungFilter;
}

export class MeldungFilter {
  sortColumn: string;
  sortOrder: string;

  freitext: string = '';
  land: number = 0;
  status: number = 0;
  offeneAnfrage: number = 0;
  datumVon: string = '';
  datumBis: string = '';

  limit: number = 0;
  page: number = 0;

  blvStatus: number = 0;
  bewilligung: MeldungBewilligungFilter;

  public constructor(init?: Partial<MeldungFilter>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): MeldungFilter {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const meldung = Object.create(MeldungFilter.prototype);
    return Object.assign(meldung, object);
  }

  toJSON(): MeldungFilterJSON {
    return Object.assign({}, this);
  }
}

export enum BlvStatusId {
  UNVOLLSTAENDIG = 20,
  VOLLSTAENDIG = 80
}
