<div class="panel panel-default mb-0">
  <div class="panel-heading">
    <div class="panel-title">
      {{ 'meldung.list.dokumente.title' | translate }}
    </div>
  </div>
  <div class="panel-body">
    <table class="dokument-filter">
      <tr>
        <td></td>
        <td class="ml-15">{{ 'meldung.list.dokumente.pflichtig' | translate }}</td>
        <td class="ml-15">{{ 'meldung.list.dokumente.vorhanden' | translate }}</td>
      </tr>
      <tr>
        <td>{{ 'dokumentTyp.detailhandels_bewilligung_kanton' | translate }}</td>
        <td></td>
        <td>
          <i [class]="getIcon('dthUploaded')" (click)="toggleIcon('dthUploaded')"></i>
        </td>
      </tr>
      <tr>
        <td>{{ 'dokumentTyp.bestaetigung_lieferengpass' | translate }}</td>
        <td></td>
        <td>
          <i [class]="getIcon('lepUploaded')" (click)="toggleIcon('lepUploaded')"></i>
        </td>
      </tr>
      <tr>
        <td>{{ 'dokumentTyp.laboranalysen_autovakzine' | translate }}</td>
        <td></td>
        <td>
          <i [class]="getIcon('labUploaded')" (click)="toggleIcon('labUploaded')"></i>
        </td>
      </tr>
      <tr>
        <td>{{ 'dokumentTyp.bewilligung_blv' | translate }}</td>
        <td>
          <i [class]="getIcon('blvRequired')" (click)="toggleIcon('blvRequired')"></i>
        </td>
        <td>
          <i [class]="getIcon('blvUploaded')" (click)="toggleIcon('blvUploaded')"></i>
        </td>
      </tr>
      <tr class="text-muted">
        <td><i>{{ 'dokumentTyp.betaeubungsmittel_bewilligung_swissmedic' | translate }}</i></td>
        <td></td>
        <td>
          <i [class]="getIcon('btmUploaded')" (click)="toggleIcon('btmUploaded')"></i>
        </td>
      </tr>
    </table>
  </div>
</div>
