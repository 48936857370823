import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { map, tap } from 'rxjs/operators';
import {ConfigurationService} from './configuration.service';
import {Settings} from './settings';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private settings: Map<string, string>;

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService) {
  }

  public get(key: string): string {
    return this.settings.get(key);
  }

  public getInteger(key: string, defaultValue: number): number {
    let value = Number(this.get(key));
    return Number.isInteger(value) ? value : defaultValue;
  }

  public initSettings(): Promise<any> {
    const config = this.configurationService.getConfig();
    return firstValueFrom(
      this.http.get(`${config.backendUrl}/settings`)
        .pipe(
          map((data: Settings[]) => new Map(data.map(it => [it.key, it.value]))),
          tap(settings => this.settings = settings)
        )
    );
  }
}
