import { HttpClient, HttpContext, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponse, Login } from '@app/core/auth/login';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';

@Injectable({ providedIn: 'root' })
export class LoginService {

  private apiBase: string;

  constructor(private http: HttpClient,
              private configurationService: ConfigurationService) {
  }

  get(user: Login): Observable<AuthResponse> {
    this.apiBase = this.configurationService.getConfig().backendUrl;
    return this.http.put<AuthResponse>(`${this.apiBase}/login`, user);
  }

  changeLanguage(user: Login): Observable<Login> {
    return this.http.put(`${this.apiBase}/login/changeLanguage`, user)
      .pipe(
        map((data) => Login.fromJSON(data))
      );
  }

  checkLoginStatus(): Observable<boolean> {
    return this.http.get(`${this.apiBase}/login/ping`, {observe: 'response', context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)})
      .pipe(
        map((resp: HttpResponse<any>) => resp.status),
        catchError((error) => error instanceof HttpErrorResponse ? of(error.status) : of(-1)),
        map((code: number) => code !== 401)
      )
  }
}
