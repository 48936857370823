import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotizBlv } from "@app/shared/classes/notiz-blv";
import Swal from "sweetalert2";
import { BackendService } from "@app/shared/services/backend.service";
import { Meldung } from "@app/shared/classes/meldung";

@Component({
  selector: 'notiz-edit',
  templateUrl: './meldung-notiz-edit.component.html'
})
export class MeldungNotizEditComponent {

  id: number;
  notizBlv: string;
  offeneAnfrage: boolean;

  constructor(private backendService: BackendService) {
  }

  @Output() result = new EventEmitter<Meldung>();

  @Input()
  set notiz(value: NotizBlv) {
    this.id = value?.id;
    this.notizBlv = value?.notizBlv || "";
    this.offeneAnfrage = value?.offeneAnfrage || false;
  }

  save(): void {
    let notiz = new NotizBlv();
    notiz.id = this.id;
    notiz.notizBlv = this.notizBlv;
    notiz.offeneAnfrage = this.offeneAnfrage;

    this.backendService.saveNotizBlv(notiz)
      .subscribe((meldung) => {
        Swal.close();
        this.result.emit(meldung);
      });
  }

  delete(): void {
    this.backendService.saveNotizBlv(new NotizBlv({id: this.id, offeneAnfrage: false}))
      .subscribe((meldung) => {
        Swal.close();
        this.result.emit(meldung);
      });
  }

}
