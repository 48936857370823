import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BackendService } from '@app/shared/services/backend.service';
import Swal from 'sweetalert2';
import { Dokument } from "@app/shared/classes/dokument";
import { DokumentTyp, DokumentTypId } from "@app/shared/classes/dokument-typ";
import { TranslateService } from "@ngx-translate/core";
import { downloadFile } from "@app/shared/download-utils";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'temporary-dokument-upload',
  templateUrl: './temporary-dokument-upload.component.html'
})
export class TemporaryDokumentUploadComponent {

  selectedDokumente: Dokument[] = [];
  dokumentTypen: DokumentTyp[] = [];
  availableUploads: DokumentTyp[] = [];
  existingUploads: DokumentTyp[] = [];
  remainingUploads: Map<DokumentTyp, number> = new Map<DokumentTyp, number>();
  errorMessage: string[];
  @Output() dokumenteChange = new EventEmitter<Dokument[]>();

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('focusAfterUpload') focusAfterUpload: ElementRef;
  selectedUpload: DokumentTyp;

  constructor(private backendService: BackendService,
              private translateService: TranslateService) {
  }

  @Input()
  set dokumente(selectedDokumente: Dokument[]) {
    this.selectedDokumente = selectedDokumente || [];
    this.refresh();
  }

  @Input()
  set typ(dokumentTypen: DokumentTyp[]) {
    this.dokumentTypen = dokumentTypen || [];
    this.refresh();
  }

  refresh(): void {
    this.errorMessage = null;

    let existingTypen = this.selectedDokumente.map( it => it.typ.id );
    let existingUploads = [];
    let remainingUploads = new Map<DokumentTyp, number>();
    this.dokumentTypen?.forEach( typ => {
      let existing = existingTypen.filter( it => it === typ.id ).length;
      if (existing > 0)  {
        existingUploads.push(typ);
      }
      let remaining = typ.maxUploadCount - existing;
      if (remaining > 0) {
        remainingUploads.set(typ, remaining);
      }
    });
    this.existingUploads = existingUploads;
    this.remainingUploads = remainingUploads;
    this.availableUploads = Array.from(remainingUploads.keys());
  }

  selectDokument(typ: DokumentTyp): void {
    this.selectedUpload = typ;
    this.focusAfterUpload.nativeElement.focus();
    this.fileInput.nativeElement.multiple = this.remainingUploads.get(typ) > 1 ? 'multiple' : null;
    this.fileInput.nativeElement.click();
  }

  uploadDokument(event): void {
    const files: FileList = event.target.files;
    if (files?.length > this.remainingUploads.get(this.selectedUpload)) {
      this.errorMessage = [this.translateService.instant('dokument.maxCount', {
        max   : this.remainingUploads.get(this.selectedUpload),
        count : files.length
      })];
    } else if (files?.length) {
      this.errorMessage = [];
      for (let i = 0; i < files?.length; i++) {
        let file = files[i];
        this.backendService.uploadDokument(this.selectedUpload, file)
          .subscribe({
            next: dokument => {
              this.selectedDokumente.push(dokument);
              this.dokumenteChange.emit(this.selectedDokumente);
              this.refresh();
            },
            error: error => this.errorMessage.push(error)
          });
      }
      this.fileInput.nativeElement.value = null;
    }
  }

  downloadDokument(dokument: Dokument): void {
    this.backendService.downloadDokument(dokument)
      .subscribe({
        next: response => {
          downloadFile(response);
          if (dokument.highlight) this.refresh();
        },
        error: console.error
      });
  }

  deleteDokument(dokument: Dokument): void {
    Swal.fire({
      text: this.translateService.instant('dokument.delete.message', {name: dokument.name}),
      icon: 'warning',
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('dokument.delete.yes'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.backendService.deleteDokument(dokument)
          .subscribe( () => {
            this.selectedDokumente = this.selectedDokumente.filter( it => it.id !== dokument.id );
            this.dokumenteChange.emit(this.selectedDokumente);
            this.refresh();
          });
      }
    });
  }

  get showAlertImmunologisch(): boolean {
    return !!this.availableUploads.find( it => it.id === DokumentTypId.LABORANALYSEN_AUTOVAKZINE );
  }

  get showAlertLieferEngpass(): boolean {
    return !!this.availableUploads.find( it => it.id === DokumentTypId.BESTAETIGUNG_LIEFERENGPASS );
  }

  get iconAlertImmunologisch(): string {
    return this.existingUploads.find( it => it.id === DokumentTypId.LABORANALYSEN_AUTOVAKZINE )
      ? ''
      : 'icon icon--before icon--exclam red';
  }

  get iconAlertLieferEngpass(): string {
    return this.existingUploads.find( it => it.id === DokumentTypId.BESTAETIGUNG_LIEFERENGPASS )
      ? ''
      : 'icon icon--before icon--exclam red';
  }
}
