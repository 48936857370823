<div class="mb-15" [innerText]="label | translate">{{ 'meldung.view.deaktivieren.grund.label' | translate }}</div>

<div *ngFor="let grund of gruende" (click)="kommentar = grund.text" class="btn-link cursor-pointer">{{ grund.bezeichnung }}</div>

<textarea autocomplete="off" aria-required="true"
          class="form-control textarea form_field form_field_textarea mt-15"
          rows="4" [(ngModel)]="kommentar"></textarea>

<div class="swal2-validation-message" *ngIf="error" [innerText]="error"></div>

<button class="btn btn-default mt-15" (click)="cancel()">{{ "global.cancel" | translate }}</button>
<button class="btn btn-primary mt-15 ml-15" (click)="save()">{{ "global.ok" | translate }}</button>
