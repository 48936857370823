import { Meldung } from "./meldung";

export interface MeldungListJSON {
  page: number;
  limit: number;

  totalResults: number;
  results: Meldung[];
  hasMore: boolean;
}

export class MeldungList {
  page: number;
  limit: number;

  totalResults: number;
  results: Meldung[];
  hasMore: boolean;

  public constructor(init?: Partial<MeldungList>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): MeldungList {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const list = Object.create(MeldungList.prototype);
    return Object.assign(list, object);
  }

  toJSON(): MeldungListJSON {
    return Object.assign({}, this);
  }
}
