<form [formGroup]="landForm" (ngSubmit)="onSubmit()">
  <div class="hidden">
    <input id="id" class="admin-settings" formControlName="id" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-2 pl-0 mb-0">
    <input id="bezeichnungDe" class="admin-settings" formControlName="bezeichnungDe" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-2 pl-0 mb-0">
    <input id="bezeichnungFr" class="admin-settings" formControlName="bezeichnungFr" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-2 pl-0 mb-0">
    <input id="bezeichnungIt" class="admin-settings" formControlName="bezeichnungIt" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-1 pl-0 mb-0">
    <input id="sortierung" class="admin-settings" formControlName="sortierung" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-1 pl-0 mb-0">
    <input type="checkbox" id="zulassungsLand" formControlName="zulassungsLand" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-1 pl-0 mb-0">
    <input type="checkbox" id="importLand" formControlName="importLand" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-0.5 pl-0 mb-0">
    <input type="checkbox" id="nutztier" formControlName="nutztier" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-0.5 pl-0 mb-0">
    <input type="checkbox" id="heimtier" formControlName="heimtier" [ngClass]="delChosen?'setting-delete':''">
  </div>

  <div class="col-md-2 pl-0 mb-0">
    <button class="btn btn-sm admin-settings" type="submit"
            [ngStyle]="{ 'visibility': landForm.dirty ? '' : 'hidden'}"
            [disabled]="landForm.invalid">
      <i class="fa fa-check"></i>
    </button>

    <button class="btn btn-sm admin-settings" type="button" [title]="'global.cancel' | translate"
            [ngStyle]="{ 'visibility': landForm.dirty ? '' : 'hidden'}"
            (click)="onReset()">
      <i class="fa fa-times"></i>
    </button>

    <button class="btn btn-sm admin-settings" type="button" [title]="'global.delete' | translate"
            (click)="deleteLand(land.id)">
      <i class="fa fa-trash-o"></i>
    </button>
  </div>
</form>
