import { Injectable, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Router,
  RouteReuseStrategy,
  UrlSegment,
  UrlSerializer
} from '@angular/router';

interface RouteStorage {

  set(key: string, handle: DetachedRouteHandle);
  get(key: string);
  delete(key: string);
}

class DefaultRouteStorage implements RouteStorage {
  private routes = new Map<string, DetachedRouteHandle>();

  constructor(private capacity: number) {}

  get(key: string) {
    return this.routes.get(key);
  }

  set(key: string, handle: DetachedRouteHandle) {
    this.delete(key);
    this.routes.set(key, handle);

    if (this.routes.size >= this.capacity) {
      Array.from(this.routes.keys())
        .slice(0, this.routes.size - this.capacity)
        .forEach(k => this.delete(k));
    }
  }

  delete(key: string) {
    this.routes.delete(key);
  }
}

@Injectable()
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeStorage: RouteStorage = new DefaultRouteStorage(10);

  constructor(private injector: Injector) {}

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseRoute || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const url = this.buildUrl(route);
    if (url.length === 0) {
      return;
    }
    if (handle == null) {
      this.routeStorage.delete(url);
    } else {
      this.routeStorage.set(url, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const url = this.buildUrl(route);
    const storedObject = this.routeStorage.get(url);
    return !!route.routeConfig && !!storedObject;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const url = this.buildUrl(route);
    const storedObject = this.routeStorage.get(url);
    if (!route.routeConfig || !storedObject) {
      return null;
    }
    return storedObject;
  }

  private buildUrl(route: ActivatedRouteSnapshot) {
    // build the complete path from the root to the input route
    const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
    const subpaths = ([] as UrlSegment[]).concat(...segments).map(segment => segment.path);

    const router = this.injector.get(Router);
    const tree = router.createUrlTree(['/', ...subpaths], {
      queryParams: route.queryParams,
      fragment: route.fragment
    });

    const urlSerializer = this.injector.get(UrlSerializer);
    return urlSerializer.serialize(tree);
  }
}
