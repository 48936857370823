import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * Passes on the KeyCloak token
 */
@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private translateService: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = this.translateService.currentLang;
    if (!language) {
      return next.handle(request);
    }
    const clone = request.clone({
      setHeaders: { 'Accept-Language': language }
    });
    return next.handle(clone);
  }
}
