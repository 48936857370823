import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Passes on the KeyCloak token
 */
@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return from(this.authenticationService.getAccessToken())
        .pipe(
          switchMap(authToken => {
            if (!authToken) {
              return next.handle(request);
            }
            const clone = request.clone({
              setHeaders: {
                Authorization: 'Bearer ' + authToken
              }
            });
            return next.handle(clone);
          })
        );
    }
}
