import { Component, EventEmitter, Input, Output } from "@angular/core";
import Swal from "sweetalert2";
import { BackendService } from "@app/shared/services/backend.service";
import { Meldung } from "@app/shared/classes/meldung";
import { TranslateService } from '@ngx-translate/core';
import { MeldungBewilligung } from '@app/shared/classes/meldung-bewilligung';
import { type Sprache } from '@app/shared/types';

@Component({
  selector: 'meldung-bewilligen-dialog',
  templateUrl: './meldung-bewilligen-dialog.component.html'
})
export class MeldungBewilligenDialogComponent {

  id: number;
  tierarztSprache: string;
  error: string;

  sprache: Sprache = 'DE';
  kommentar: string;

  requestPending = false;
  preview: string[] = null;
  page = 0;

  constructor(private backendService: BackendService,
              private translateService: TranslateService) {
  }

  @Output() result = new EventEmitter<Meldung>();

  @Input()
  set meldung(value: Meldung) {
    this.id = value?.id;
    this.error = null;
    if (value) {
      this.backendService.letzteSprache(this.id)
        .subscribe({
          next: sprache => {
            if (sprache) {
              this.sprache = sprache;
              this.tierarztSprache = this.translateService.instant('meldung.view.bewilligen.sprache', {
                sprache: this.translateService.instant('global.sprache.' + sprache.toLowerCase())
              });
            } else {
              this.tierarztSprache = null;
            }
          },
          error: console.error
        });
    }
  }

  cancel(): void {
    Swal.close();
  }

  openPreview(): void {
    this.requestPending = true;
    this.backendService.bewilligeMeldungPreview(this.bewilligungRequest)
      .subscribe({
        next: result => {
          this.page = 0;
          this.preview = result.pages;
          this.requestPending = false;
        },
        error: error => {
          console.error(error);
          this.requestPending = false;
        }
      });
  }

  get bewilligungRequest(): MeldungBewilligung {
    return new MeldungBewilligung({
      id        : this.id,
      sprache   : this.sprache,
      kommentar : this.kommentar
    })
  }

  save(): void {
    this.requestPending = true;
    this.backendService.bewilligeMeldung(this.bewilligungRequest)
      .subscribe( meldung => {
        Swal.close();
        this.result.emit(meldung)
      });
  }
}
