import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthorizationService } from './authorization.service';

@Directive({
  selector: '[appIsBlv]'
})
export class IsBlvDirective implements OnInit, OnDestroy {

  destroyed$ = new Subject();
  isVisible = false;

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>,
              private authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.authorizationService.authorities$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((authorities) => {

      // If he doesn't have any roles, we clear the viewContainerRef
      if (authorities.length === 0) {
        this.viewContainerRef.clear();
      }

      // If the user has the role needed to
      // render this component we can add it
      const isBlv = this.authorizationService.isBlv()
      if (isBlv) {
        // If it is already visible (which can happen if
        // his roles changed) we do not need to add it a second time
        if (!this.isVisible) {
          // We update the `isVisible` property and add the
          // templateRef to the view using the
          // 'createEmbeddedView' method of the viewContainerRef
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        // If the user does not have the role,
        // we update the `isVisible` property and clear
        // the contents of the viewContainerRef
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });
  }

  // Clear the subscription on destroy
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
