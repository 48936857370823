import {Land} from "./land";
import {Packung} from "./packung";
import { Nutzung } from "@app/shared/classes/nutzung";

export interface PraeparatJSON {
  nutzung: Nutzung;
  spezies: string;
  zulassungsLand: Land;
  zulassungsNr: string;
  name: string;
  zulassungsNrCh: string;
  anhang5: boolean;
  umgewidmet: boolean;
  auslaendischeZulassungTierart: boolean;
  auslaendischeZulassungIndikation: boolean;
  bienen: boolean;
  immunologisch: boolean;
  hersteller: string;
  bestandteile: string;
  tierhalter: string;
  betaeubungsmittel: boolean;
  packungen: Packung[];
}

export class Praeparat {
  nutzung: Nutzung;
  spezies: string;
  zulassungsLand: Land;
  zulassungsNr: string;
  name: string;
  zulassungsNrCh: string;
  anhang5: boolean;
  umgewidmet: boolean;
  auslaendischeZulassungTierart: boolean;
  auslaendischeZulassungIndikation: boolean;
  bienen: boolean;
  immunologisch: boolean;
  hersteller: string;
  bestandteile: string;
  tierhalter: string;
  betaeubungsmittel: boolean;
  packungen: Packung[] = [new Packung()];

  static fromJSON(json: string | object): Praeparat {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const praeparat = Object.create(Praeparat.prototype);
    return Object.assign(praeparat, object);
  }

  toJSON(): PraeparatJSON {
    return Object.assign({}, this);
  }
}
