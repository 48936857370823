<div class="container-fluid">
  <!-- Actionbar -->
  <div class="row">
    <div class="col-sm-6">
      <a routerLink=".." class="btn btn-default" role="button"
         [matTooltip]="'global.tooltip.home' | translate"
         matTooltipPosition="right">
        <span class="glyphicon glyphicon-home"></span>
      </a>
    </div>
  </div>
  <!-- Banner -->
  <div class="alert alert-warning" *ngIf="storedMeldung">
    <a class="close" (click)="storedMeldung = null">
      <span class="icon icon--close"></span>
    </a>
    <p>{{ "meldung.new.autosave.title" | translate }}</p>
    <button (click)="restoreAutosave()" style="margin-top: 15px">
      {{ "meldung.new.autosave.button" | translate }}
    </button>
  </div>
  <!-- Meldung-Formular -->
  <div class="row">
    <div class="col-sm-12">
      <h1>
        {{ "meldung.new.title" | translate }}
      </h1>
      <form [formGroup]="meldungForm" (ngSubmit)="send()" autocomplete="off">
        <fieldset>
          <legend>
            {{ "meldung.bereich.identifikation" | translate }}
          </legend>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group">
                <label for="uid" class="col-sm-4 control-label">
                  {{ "meldung.feld.uid" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input disabled class="form-control" name="uid" id="uid"
                         [value]="login.uid" type="text">
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-0">
              <div class="form-group">
                <label for="uid" class="col-sm-4 control-label">
                  {{ "meldung.feld.burNr" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input disabled class="form-control" name="burnr" id="burnr"
                         [value]="login.burNr ?? ''" type="text">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group {{hasError('praxis') ? 'has-error' : ''}}">
                <label for="praxis" class="col-sm-2 control-label required">
                  {{ "meldung.feld.praxis" | translate }}
                </label>
                <div class="col-sm-10 padding-left-md-10 mb-0">
                  <input autocomplete="off" aria-required="true" maxlength="500"
                            class="form-control textarea form_field form_field_textarea"
                            name="praxis" id="praxis" onkeydown=""
                            formControlName="praxis" type="text"
                            style="resize: none; overflow: hidden;" (change)="autosave()">
                  <span class="help-block" role="alert" *ngIf="hasError('praxis')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            {{ "meldung.bereich.bestellendePerson" | translate }}
            <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
               [matTooltip]="'tooltip.bestellendePerson' | setting:true"
               *ngIf="'tooltip.bestellendePerson' | setting:true"></i>
          </legend>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group {{hasError('vorname') ? 'has-error' : ''}}">
                <label for="vorname" class="col-sm-4 control-label required">
                  {{ "meldung.feld.vorname" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="vorname" id="vorname" type="text" formControlName="vorname"
                         (change)="autosave()" maxlength="100">
                  <span class="help-block" role="alert" *ngIf="hasError('vorname')">
                      {{ 'global.validation.pflichtfeld' | translate }}
                </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group {{hasError('nachname') ? 'has-error' : ''}}">
                <label for="nachname" class="col-sm-4 control-label required">
                  {{ "meldung.feld.nachname" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="nachname" id="nachname" value="" formControlName="nachname"
                         (change)="autosave()" type="text" maxlength="100">
                  <span class="help-block" role="alert" *ngIf="hasError('nachname')">
                      {{ 'global.validation.pflichtfeld' | translate }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            {{ "meldung.bereich.kontaktangaben" | translate }}
          </legend>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group {{hasError('email') ? 'has-error' : ''}}">
                <label for="email" class="col-sm-4 control-label required">
                  {{ "meldung.feld.email" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="email" id="email" value="" type="text" formControlName="email"
                         (change)="autosave()" maxlength="255">
                  <span class="help-block" role="alert" *ngIf="hasError('email')">
                          {{ 'global.validation.email' | translate }}
                </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="telefon" class="col-sm-4 control-label">
                  {{ "meldung.feld.telefon" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="telefon" id="telefon" value="" formControlName="telefon"
                         (change)="autosave()" type="text" maxlength="20">
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            {{ "meldung.bereich.importAngaben" | translate }}
            <span class="pull-right">
              <a [href]="'url.importAngaben' | setting:true"
                 class="icon icon--after icon--external" target="_blank">
                {{ "meldung.bereich.importAngaben.link" | translate }}
              </a>
            </span>
          </legend>
          <div class="row">
            <div class="col-sm-12 mb-0">
              <div class="form-group {{hasError('grund') ? 'has-error' : ''}}">
                <label for="grund" class="col-sm-2 control-label required">
                  {{ "meldung.feld.grund" | translate }}
                </label>
                <div class="col-sm-10 padding-left-md-10 mb-0">
                  <select name="grund" id="grund" class="form-control width100" formControlName="grund"
                          (change)="autosave()">
                    <option hidden [ngValue]="null" selected>{{ 'global.select' | translate }}</option>
                    <option *ngFor="let grund of importGruende" [value]="grund.id">
                      {{grund.bezeichnung}}
                    </option>
                  </select>
                  <span class="help-block" role="alert" *ngIf="hasError('grund')">
                  {{ 'global.validation.pflichtfeld' | translate }}
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('grundKommentar') ? 'has-error' : ''}}">
                <label for="grundKommentar"
                       class="col-sm-4 control-label {{isGrundKommentarPflicht ? 'required' : ''}}">
                  {{ "meldung.feld.grundKommentar" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <textarea autocomplete="off" aria-required="true" maxlength="255"
                            class="form-control textarea form_field form_field_textarea"
                            name="grundKommentar" id="grundKommentar" rows="4" onkeydown=""
                            style="resize: none; overflow: paged-y-controls;"
                            formControlName="grundKommentar" (change)="autosave()"></textarea>
                  <span class="help-block" role="alert" *ngIf="hasError('grundKommentar')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            {{ "meldung.bereich.praeparat" | translate }}
            <span class="pull-right">
                <a [href]="'url.praeparat' | setting:true"
                   class="icon icon--after icon--external" target="_blank">
                  {{ "meldung.bereich.praeparat.link" | translate }}
                </a>
            </span>
          </legend>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('praeparat') ? 'has-error' : ''}}">
                <label for="praeparat" class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.name" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="praeparat" id="praeparat" value="" type="text"
                         formControlName="praeparat" (change)="autosave()" maxlength="100" autocomplete="off">
                  <span class="help-block" role="alert" *ngIf="hasError('praeparat')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('nutzung') ? 'has-error' : ''}}">
                <label for="nutzung" class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.nutzung" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <select name="nutzung" id="nutzung" class="form-control width100"
                          formControlName="nutzung" (change)="autosave()">
                    <option hidden [ngValue]="null" selected>{{ 'global.select' | translate }}</option>
                    <option *ngFor="let nutzung of nutzungen" [value]="nutzung.id">
                      {{ nutzung.messageProperty | translate }}
                    </option>
                  </select>
                  <span class="help-block" role="alert" *ngIf="hasError('nutzung')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('spezies') ? 'has-error' : ''}}">
                <label for="spezies" class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.spezies" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.spezies' | setting:true"
                     *ngIf="'tooltip.spezies' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="spezies" id="spezies" value="" type="text"
                         formControlName="spezies" (change)="autosave()" maxlength="50">
                  <span class="help-block" role="alert" *ngIf="hasError('spezies')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('zulassungsNr') ? 'has-error' : ''}}">
                <label for="zulnr" class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.zulassungsNr" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.zulassungsNr' | setting:true"
                     *ngIf="'tooltip.zulassungsNr' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="zulnr" id="zulnr" value="" type="text"
                         formControlName="zulassungsNr" (change)="autosave()" maxlength="50">
                  <span class="help-block" role="alert" *ngIf="hasError('zulassungsNr')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('zulassungsLand') ? 'has-error' : ''}}">
                <label for="zulassungsland" class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.zulassungsLand" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <select name="zulassungsland" id="zulassungsland" class="form-control width100"
                          formControlName="zulassungsLand" (change)="autosave()">
                    <option hidden [ngValue]="null" selected>{{ 'global.select' | translate }}</option>
                    <option *ngFor="let land of zulassungsLaender" [value]="land.id">
                      {{land.bezeichnung}}
                    </option>
                  </select>
                  <span class="help-block" role="alert" *ngIf="hasError('zulassungsLand')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-0">
              <div class="col-sm-12 mb-0">
                <a [href]="'url.zulassungsLand' | setting:true"
                   class="icon icon--after icon--external" target="_blank">
                  {{ "meldung.feld.praeparat.zulassungsLand.link" | translate }}
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="row">
                <div class="col-sm-12 mb-0" *ngIf="showZulassungsNrCh">
                  <div class="form-group {{hasError('zulassungsNrCh') ? 'has-error' : ''}}">
                    <label for="zulnrch" class="col-sm-4 control-label required">
                      {{ "meldung.feld.praeparat.zulassungsNrCh" | translate }}
                      <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                         [matTooltip]="'tooltip.zulassungsNrCh' | setting:true"
                         *ngIf="'tooltip.zulassungsNrCh' | setting:true"></i>
                    </label>
                    <div class="col-sm-8 mb-0">
                      <input class="form-control" name="zulnrch" id="zulnrch" value="" type="text"
                             formControlName="zulassungsNrCh" (change)="autosave()" maxlength="25">
                      <span class="help-block" role="alert" *ngIf="hasError('zulassungsNrCh')">
                        {{ 'global.validation.pflichtfeld' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('importLand') ? 'has-error' : ''}}">
                <label for="importLand" class="col-sm-4 control-label required">
                  {{ "meldung.feld.importLand" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.importLand' | setting:true"
                     *ngIf="'tooltip.importLand' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <select name="importLand" id="importLand" class="form-control width100"
                          formControlName="importLand" (change)="autosave()">
                    <option hidden [ngValue]="null" selected>{{ 'global.select' | translate }}</option>
                    <option *ngFor="let land of importLaender" [value]="land.id">
                      {{land.bezeichnung}}
                    </option>
                  </select>
                  <span class="help-block" role="alert" *ngIf="hasError('importLand')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="haendlerAusland" class="col-sm-4 control-label">
                  {{ "meldung.feld.haendlerAusland" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.haendlerAusland' | setting:true"
                     *ngIf="'tooltip.haendlerAusland' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <input class="form-control" name="haendlerAusland" id="haendlerAusland" value=""
                         [placeholder]="'placeholder.haendler' | setting:true"
                         formControlName="haendlerAusland" (change)="autosave()" type="text" maxlength="255">
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-0">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('anhang5') ? 'has-error' : ''}}">
                <label class="col-sm-4 control-label required"
                       [innerHTML]="'meldung.feld.praeparat.anhang5' | translate | injectUrl:'url.anhang5'">
                </label>
                <div class="col-sm-8 mb-0">
                  <div class="radio radio--left">
                    <label for="anhang5True">
                      <input type="radio" id="anhang5True" name="anhang5" [value]="true"
                             formControlName="anhang5" (change)="autosave()">
                      {{ "global.yes" | translate }}
                    </label>
                  </div>
                  <div class="radio radio--left">
                    <label for="anhang5False">
                      <input type="radio" id="anhang5False" name="anhang5" [value]="false"
                             formControlName="anhang5" (change)="autosave()">
                      {{ "global.no" | translate }}
                    </label>
                  </div>
                  <span class="help-block" role="alert" *ngIf="hasError('anhang5')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('umgewidmet') ? 'has-error' : ''}}">
                <label class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.umgewidmet" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.umgewidmet' | setting:true"
                     *ngIf="'tooltip.umgewidmet' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <div class="radio radio--left">
                    <label for="umgewidmetTrue">
                      <input type="radio" id="umgewidmetTrue" name="umgewidmet" [value]="true"
                             formControlName="umgewidmet" (change)="autosave()">
                      {{ "global.yes" | translate }}
                    </label>
                  </div>
                  <div class="radio radio--left">
                    <label for="umgewidmetFalse">
                      <input type="radio" id="umgewidmetFalse" name="umgewidmet" [value]="false"
                             formControlName="umgewidmet" (change)="autosave()">
                      {{ "global.no" | translate }}
                    </label>
                  </div>
                  <span class="help-block" role="alert" *ngIf="hasError('umgewidmet')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('bienen') ? 'has-error' : ''}}">
                <label class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.bienen" | translate }}
                </label>
                <div class="col-sm-8 mb-0">
                  <div class="radio radio--left">
                    <label for="bienenTrue">
                      <input type="radio" id="bienenTrue" name="bienen" [value]="true"
                             formControlName="bienen" (change)="autosave()">
                      {{ "global.yes" | translate }}
                    </label>
                  </div>
                  <div class="radio radio--left">
                    <label for="bienenFalse">
                      <input type="radio" id="bienenFalse" name="bienen" [value]="false"
                             formControlName="bienen" (change)="autosave()">
                      {{ "global.no" | translate }}
                    </label>
                  </div>
                  <span class="help-block" role="alert" *ngIf="hasError('bienen')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-0">
              <div class="form-group {{hasError('immunologisch') ? 'has-error' : ''}}">
                <label class="col-sm-4 control-label required">
                  {{ "meldung.feld.praeparat.immunologisch" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.immunologisch' | setting:true"
                     *ngIf="'tooltip.immunologisch' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <div class="radio radio--left">
                    <label for="immunologischTrue">
                      <input type="radio" id="immunologischTrue" name="immunologisch" [value]="true"
                             formControlName="immunologisch" (change)="autosave()">
                      {{ "global.yes" | translate }}
                    </label>
                  </div>
                  <div class="radio radio--left">
                    <label for="immunologischFalse">
                      <input type="radio" id="immunologischFalse" name="immunologisch" [value]="false"
                             formControlName="immunologisch" (change)="autosave()">
                      {{ "global.no" | translate }}
                    </label>
                  </div>
                  <span class="help-block" role="alert" *ngIf="hasError('immunologisch')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="showImmunologischFelder">
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12 mb-0">
                  <div class="form-group {{hasError('hersteller') ? 'has-error' : ''}}">
                    <label for="hersteller" class="col-sm-4 control-label required">
                      {{ "meldung.feld.praeparat.hersteller" | translate }}
                    </label>
                    <div class="col-sm-8 mb-0">
                      <input class="form-control" name="hersteller" id="hersteller" value="" type="text"
                             formControlName="hersteller" (change)="autosave()" maxlength="50">
                      <span class="help-block" role="alert" *ngIf="hasError('hersteller')">
                        {{ 'global.validation.pflichtfeld' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-0">
                  <div class="form-group {{hasError('bestandteile') ? 'has-error' : ''}}">
                    <label for="bestandteile" class="col-sm-4 control-label required">
                      {{ "meldung.feld.praeparat.bestandteile" | translate }}
                    </label>
                    <div class="col-sm-8 mb-0">
                      <input class="form-control" name="bestandteile" id="bestandteile" value="" type="text"
                             formControlName="bestandteile" (change)="autosave()" maxlength="100">
                      <span class="help-block" role="alert" *ngIf="hasError('bestandteile')">
                        {{ 'global.validation.pflichtfeld' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-0">
                  <div class="form-group {{hasError('tierhalter') ? 'has-error' : ''}}">
                    <label for="tierhalter" class="col-sm-4 control-label required">
                      {{ "meldung.feld.praeparat.tierhalter" | translate }}
                      <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                         [matTooltip]="'tooltip.tierhalter' | setting:true"
                         *ngIf="'tooltip.tierhalter' | setting:true"></i>
                    </label>
                    <div class="col-sm-8 mb-0">
                      <input class="form-control" name="tierhalter" id="tierhalter" value="" type="text"
                             formControlName="tierhalter" (change)="autosave()" maxlength="100">
                      <span class="help-block" role="alert" *ngIf="hasError('tierhalter')">
                        {{ 'global.validation.pflichtfeld' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="col-sm-6 mb-0">
                <div class="col-sm-8 col-sm-offset-4 mb-0">
                  <label class="control-label required" style="text-align: left">
                    {{ "meldung.feld.praeparat.packungen.groesse" | translate }}
                    <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                       [matTooltip]="'tooltip.groesse' | setting:true"
                       *ngIf="'tooltip.groesse' | setting:true"></i>
                  </label>
                </div>
              </div>
              <div class="col-sm-6 mb-0">
                <div class="col-sm-8 mb-0">
                  <label class="control-label required" style="text-align: left">
                    {{ "meldung.feld.praeparat.packungen.anzahl" | translate }}
                  </label>
                </div>
              </div>
            </div>
            <div formArrayName="packungen"
                 *ngFor="let packung of packungen.controls; let i = index; trackBy: trackByIndex">
              <div class="col-sm-12 mb-0">
                <div [formGroupName]="i">
                  <div class="col-sm-6 mb-0 pl-0">
                    <div
                      class="form-group {{meldungFormSubmitted && packungen.controls[i].get('groesse').invalid ? 'has-error' : ''}}">
                      <label [for]="'packung_groesse_' + i" class="col-sm-4 control-label text-right">{{i + 1}}.</label>
                      <div class="col-sm-8 mb-0">
                        <input class="form-control" [name]="'packung_groesse_' + i" [id]="'packung_groesse_' + i"
                               value="" type="text" formControlName="groesse" (change)="autosave()" maxlength="50">
                        <span class="help-block" role="alert"
                              *ngIf="(meldungFormSubmitted && packungen.controls[i].get('groesse').invalid)">
                          {{ 'global.validation.pflichtfeld' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-3 mb-0">
                    <div
                      class="form-group {{meldungFormSubmitted && packungen.controls[i].get('anzahl').invalid ? 'has-error' : ''}}">
                      <div class="col-sm-8 line-break mb-0">
                        <input class="form-control" [name]="'packung_anzahl_' + i" [id]="'packung_anzahl_' + i"
                               value="" type="text" formControlName="anzahl" (change)="autosave()" [maxLength]="9">
                        <span class="help-block" role="alert"
                              *ngIf="(meldungFormSubmitted && packungen.controls[i].get('anzahl').invalid)">
                          {{ 'global.validation.numeric' | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mb-0">
              <div class="col-sm-6 mb-0">
              <div class="form-group">
                  <div class="col-sm-1 col-sm-offset-4">
                    <i class="glyphicon glyphicon-plus {{ packungen.length < 5 ? 'cursor-pointer hover-red' : 'text-muted' }}" (click)="addPackung()"></i>
                  </div>
                  <div class="col-sm-1">
                    <i class="glyphicon glyphicon-minus cursor-pointer hover-red" *ngIf="packungen.length > 1" (click)="removePackung()"></i>
                  </div>
                  <div class="col-sm-1">
                    <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                       [matTooltip]="'tooltip.packungen' | setting:true"
                       *ngIf="'tooltip.packungen' | setting:true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group {{hasError('hauptIndikation') ? 'has-error' : ''}}">
                <label for="indik" class="col-sm-4 control-label required">
                  {{ "meldung.feld.hauptindikation" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.hauptindikation' | setting:true"
                     *ngIf="'tooltip.hauptindikation' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <textarea autocomplete="off" aria-required="true" maxlength="255"
                            class="form-control textarea form_field form_field_textarea"
                            name="indik" id="indik" rows="4" onkeydown=""
                            style="resize: none; overflow: paged-y-controls;"
                            formControlName="hauptIndikation" (change)="autosave()"></textarea>
                  <span class="help-block" role="alert" *ngIf="hasError('hauptIndikation')">
                    {{ 'global.validation.pflichtfeld' | translate }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="comment2" class="col-sm-4 control-label">
                  {{ "meldung.feld.kommentar" | translate }}
                  <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                     [matTooltip]="'tooltip.kommentar' | setting:true"
                     *ngIf="'tooltip.kommentar' | setting:true"></i>
                </label>
                <div class="col-sm-8 mb-0">
                  <textarea autocomplete="off" aria-required="true" maxlength="1000"
                            class="form-control textarea form_field form_field_textarea"
                            name="comment2" id="comment2" rows="4" onkeydown=""
                            style="resize: none; overflow: paged-y-controls;"
                            formControlName="kommentar" (change)="autosave()"></textarea>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            {{ "dokument.title" | translate }}
            <ng-template [ngIf]="showAlertBewilligungsPflichtig" [ngIfElse]="dokumentTooltipFrei">
              <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                 [matTooltip]="'tooltip.dokument.pflichtig' | setting:true"
                 *ngIf="'tooltip.dokument.pflichtig' | setting:true"></i>
            </ng-template>
            <ng-template #dokumentTooltipFrei>
              <i class="glyphicon glyphicon-question-sign" matTooltipPosition="right"
                 [matTooltip]="'tooltip.dokument.frei' | setting:true"
                 *ngIf="'tooltip.dokument.frei' | setting:true"></i>
            </ng-template>
          </legend>
          <div class="row">
            <div class="col-sm-12">
              <temporary-dokument-upload [typ]="dokumentTypen" [(dokumente)]="dokumente"></temporary-dokument-upload>
            </div>
          </div>
        </fieldset>

        <div class="row" *ngIf="showAlertBewilligungsPflichtig">
          <div class="col-sm-offset-2 col-sm-8 mb-0">
            <div class="alert alert-warning" [innerHTML]="'meldung.feld.praeparat.alert.bewilligungspflichtig' | translate | marked"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="form-group {{hasError('bestaetigung') ? 'has-error' : ''}}">
              <div class="col-sm-offset-2 col-sm-8 mb-0 padding-left-full-width-element">
                <div class="checkbox checkbox--left">
                  <label for="bestaetigung">
                    <input type="checkbox" id="bestaetigung" value="true" formControlName="bestaetigung">
                    <span class="line-break" [innerHTML]="'meldung.feld.bestaetigung.gamma' | translate | injectUrl:'url.bestaetigung'"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="col-sm-8 col-sm-offset-4">
              <button type="submit" class="btn btn-default ml-0" role="button">
                {{ "meldung.new.senden" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
