export interface StatusJSON {
  id: number;
  messageProperty: string;
}

export class Status {
  id: number;
  messageProperty: string;

  static fromJSON(json: string | object): Status {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const status = Object.create(Status.prototype);
    return Object.assign(status, object);
  }

  toJSON(): StatusJSON {
    return Object.assign({}, this);
  }
}

export enum StatusId {
  OFFEN = 20,
  AUTOMATISCH_BEWILLIGT = 30,
  BEWILLIGT = 40,
  STORNO_ANFRAGE = 50,
  STORNIERT = 90
}
