import { HttpResponse } from "@angular/common/http";
import { saveAs } from 'file-saver';

export function downloadFile(response: HttpResponse<Blob>) {
  const blob = new Blob([response.body], {type: response.headers.get('Content-Type')});
  const contentDisposition: string = response.headers.get('content-disposition');
  const r = /filename="(.+)"/;
  const filename = r.exec(contentDisposition)[1];
  saveAs(blob, filename);
}
