<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">{{ 'admin.error' | translate }}</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <div class="row admin">
      <p class="admin-warning"><b>{{ 'admin.warning' | translate }}</b></p>
    </div>
    <app-admin-settings-new (reloadEntries)="reloadEntries()"></app-admin-settings-new>
    <div class="row admin">
      <div class="col-md-2 pl-0 mb-0"><p class="admin"><b>{{ 'admin.settings.column.key' | translate }}</b></p></div>
      <div class="col-md-3 pl-0 mb-0"><p class="admin"><b>{{ 'admin.settings.column.value' | translate }}</b></p></div>
      <div class="col-md-3 pl-0 mb-0"><p class="admin"><b>{{ 'admin.settings.column.description' | translate }}</b></p></div>
      <div class="col-md-2 pl-0 mb-0"><p class="admin"><b>{{ 'admin.settings.column.date' | translate }}</b></p></div>
      <div class="col-md-2 pl-0 mb-0"></div>
    </div>
    <div class="row admin" *ngFor="let set of settings; let i = index"
         app-admin-settings-entry
         [set]="set"
         (reloadEntries)="reloadEntries()"
         (deleted)="onSettingDeleted(i)">
    </div>
  </ng-template>
</div>
