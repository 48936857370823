import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateMerge',
  pure: true
})
export class DateMergePipe extends DatePipe implements PipeTransform {

  override transform(from: any, to?: any): any {
    const fromString = super.transform(from, 'dd.MM.yyyy HH:mm');

    // Wenn die Variable to nicht übergeben wird, liefern wir nur das formatierte Von-Datum
    if (to === undefined) {
      return fromString;
    } else {
      const fromDate = new Date(from);
      const toDate = new Date(to);

      if (fromDate.getTime() === toDate.getTime()) {
        return super.transform(from, 'dd.MM.yyyy');
      }

      if (fromDate.setHours(0, 0, 0, 0) === toDate.setHours(0, 0, 0, 0)) {
        return fromString + ' - ' + super.transform(to, 'HH:mm');
      } else {
        return fromString + ' - ' + super.transform(to, 'dd.MM.yyyy HH:mm');
      }
    }
  }
}
