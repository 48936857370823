export interface MeldungStornoJSON {
  id: number;
  kommentar: string;
}

export class MeldungStorno {
  id: number;
  kommentar: string;

  public constructor(init?: Partial<MeldungStorno>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): MeldungStorno {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const meldung = Object.create(MeldungStorno.prototype);
    return Object.assign(meldung, object);
  }

  toJSON(): MeldungStornoJSON {
    return Object.assign({}, this);
  }
}
