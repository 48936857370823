export interface SettingsJSON {
  id?: number;
  key: string;
  value: string;
  description: string;
  mutDate?: string;
  mutUser?: string;
  mutUserName?: string;
}

export class Settings {
  id?: number;
  key: string;
  value: string;
  description: string;
  mutDate?: string;
  mutUser?: string;
  mutUserName?: string;

  static fromJSON(json: string | object): Settings {
    if (!json) {
      return null;
    }
    const thema = Object.create(Settings.prototype);
    return Object.assign(thema, json);
  }

  toJSON(): SettingsJSON {
    return Object.assign({}, this);
  }
}
