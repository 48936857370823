import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from '@app/core/config/configuration.service';
import {map} from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CryptoService {
  private apiBase: string;
  private key: CryptoKey;

  constructor(private http: HttpClient,
              configurationService: ConfigurationService) {
    this.apiBase = configurationService.getConfig().backendUrl;
  }

  private async getKey(): Promise<CryptoKey> {
    if (this.key) {
      return Promise.resolve(this.key);
    }
    // Request private key from backend
    return firstValueFrom(
      this.http.get(`${this.apiBase}/login/cryptoKey`)
        .pipe(
          map((data: Map<string, string>) => data['key'])
      )).then( stringKey => {
        // Convert Base64 encoded key into CryptoKey
        let binaryKey = Uint8Array.from(atob(stringKey), c => c.charCodeAt(0));
        let algorithm: AesKeyAlgorithm = {name: 'AES-GCM', length: 256};
        return crypto.subtle.importKey('raw', binaryKey, algorithm, false, ['encrypt', 'decrypt']);
      }).then(scKey => {
        // Store CryptoKey for later and return value
        this.key = scKey;
        return Promise.resolve(this.key);
      });
  }

  async encrypt(plaintext: string): Promise<string> {
    if (crypto.subtle) {
      // Generate random IV
      let scIv = crypto.getRandomValues(new Uint8Array(12));
      return this.getKey().then(scKey => {
        // Encrypt plaintext
        let algorithm: AesGcmParams = {name: 'AES-GCM', iv: scIv};
        let scPlaintext = new TextEncoder().encode(plaintext);
        return crypto.subtle.encrypt(algorithm, scKey, scPlaintext)
      }).then(scCiphertext => {
        // Convert results to Base64
        let ivString = btoa(String.fromCharCode(...new Uint8Array(scIv)));
        let ciphertextString = btoa(String.fromCharCode(...new Uint8Array(scCiphertext)));
        // Return IV and ciphertext
        return Promise.resolve(ivString + '.' + ciphertextString);
      });
    }
    return Promise.reject();
  }

  async decrypt(ciphertext: string): Promise<string> {
    // Split IV and ciphertext
    let parts = (ciphertext || '').split('.');
    if (parts.length == 2 && crypto.subtle) {
      let ivString = parts[0];
      let ciphertextString = parts[1];
      return this.getKey().then(scKey => {
        // Convert Base64 to byte array
        let scIv = Uint8Array.from(atob(ivString), c => c.charCodeAt(0));
        let scCiphertext = Uint8Array.from(atob(ciphertextString), c => c.charCodeAt(0));
        // Decrypt ciphertext
        let algorithm: AesGcmParams = {name: 'AES-GCM', iv: scIv};
        return crypto.subtle.decrypt(algorithm, scKey, scCiphertext)
      }).then(scPlaintext => {
        // Convert results to string
        let plaintext = new TextDecoder().decode(scPlaintext);
        return Promise.resolve(plaintext);
      });
    }
    return Promise.reject();
  }
}
