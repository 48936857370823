export interface MeldungArtJSON {
  id: number;
  letter: string;
}

export class MeldungArt {
  id: number;
  letter: string;

  static fromJSON(json: string | object): MeldungArt {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const art = Object.create(MeldungArt.prototype);
    return Object.assign(art, object);
  }

  toJSON(): MeldungArtJSON {
    return Object.assign({}, this);
  }
}

export enum MeldungArtId {
  ALPHA = 10,
  BETA = 20,
  GAMMA = 30
}
