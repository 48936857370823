import { type Sprache } from '@app/shared/types';

export interface MeldungBewilligungJSON {
  id: number;
  sprache: Sprache;
  kommentar: string;
}

export class MeldungBewilligung {
  id: number;
  sprache: Sprache;
  kommentar: string;

  public constructor(init?: Partial<MeldungBewilligung>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): MeldungBewilligung {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const bewilligung = Object.create(MeldungBewilligung.prototype);
    return Object.assign(bewilligung, object);
  }

  toJSON(): MeldungBewilligungJSON {
    return Object.assign({}, this);
  }
}
export interface MeldungBewilligungPreviewJSON {
  pages: string[];
}

export class MeldungBewilligungPreview {
  pages: string[];

  public constructor(init?: Partial<MeldungBewilligungPreview>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): MeldungBewilligungPreview {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const bewilligungPreview = Object.create(MeldungBewilligungPreview.prototype);
    return Object.assign(bewilligungPreview, object);
  }

  toJSON(): MeldungBewilligungPreviewJSON {
    return Object.assign({}, this);
  }
}
