import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private authenticationService: AuthenticationService,
              private translate: TranslateService) {
    translate.addLangs(['de', 'fr', 'it', 'en']);
    translate.setDefaultLang('de');

    const user = authenticationService.getUser();
    if (user) {
      translate.use(user.language);
    }
  }
}
