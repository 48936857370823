import { Injectable } from '@angular/core';
import { Configuration } from '@app/core/config/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private loaded = false;
  private configuration: Configuration;

  public getConfig(): Configuration {
    return this.configuration;
  }

  // the return value (Promise) of this method is used as an APP_INITIALIZER,
  // so the application's initialization will not complete until the Promise resolves.
  public load(): Promise<Configuration> {
    if (this.loaded) {
      return Promise.resolve(this.configuration);
    }
    // TODO: Strategie betreffend Caching?
    return fetch(`config/config.json`)
      .then(response => response.json())
      .then(config => {
        this.configuration = config as Configuration;
        this.loaded = true;
        return this.configuration;
      })
      .catch(error => {
        console.error('error loading configuration:', error);
        return null;
      });
  }
}
