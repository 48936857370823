import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {Router} from '@angular/router';
import {Meldung} from '@app/shared/classes/meldung';
import {CryptoService} from '@app/shared/services/crypto.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit, OnDestroy {
  savedMeldungCiphertext: string;
  savedMeldung: Meldung;
  loading = false;
  docs: Document[];
  sucheForm: UntypedFormGroup;
  term = '';

  constructor (private formBuilder: UntypedFormBuilder,
               private cryptoService: CryptoService,
               private router: Router) {
  }

  ngOnInit() {
    this.sucheForm = this.formBuilder.group({
      term: this.term
    });

    this.savedMeldungCiphertext = localStorage.getItem('savedMeldung');

    if (this.savedMeldungCiphertext) {
      this.cryptoService.decrypt(this.savedMeldungCiphertext).then(meldungPlaintext => {
        this.savedMeldung = Meldung.fromJSON(meldungPlaintext);
      });
    }
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    localStorage.removeItem('savedMeldung');
  }

  onSubmitSuche() {
    const sucheFormValues = this.sucheForm.value;

    this.search(sucheFormValues.term);
  }

  search(term: string) {
    this.router.navigate(['/search/content'], {
      queryParams: {
        term,
        status: ['10', '20']
      }
    });
  }

  resetSearch() {
    this.sucheForm.reset();

    this.router.navigate([], {
      queryParams: {},
    });
  }
}
