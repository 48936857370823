import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Meldung } from '@app/shared/classes/meldung';
import { BackendService } from '@app/shared/services/backend.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { AuthorizationService } from "@app/core/auth/authorization.service";
import { downloadFile } from "@app/shared/download-utils";
import { StatusId } from "@app/shared/classes/status";
import { NutzungId } from "@app/shared/classes/nutzung";
import { MeldungArtId } from "@app/shared/classes/meldung-art";

@Component({
  selector: 'app-meldung',
  templateUrl: './meldung.component.html'
})
export class MeldungComponent implements OnInit {
  showCreatedMeldung: boolean;
  showBewilligeMeldung: boolean;
  meldung: Meldung;
  date: Date;
  stornoDisabled: boolean;
  preventRedirect: boolean;
  @ViewChild('notizEdit') notizEdit: ElementRef;
  @ViewChild('bewilligenDialog') bewilligenDialog: ElementRef;
  @ViewChild('deaktivierenDialog') deaktivierenDialog: ElementRef;

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private route: ActivatedRoute,
              private authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
    // TODO: Datum / Meldungsnummer aus gespeicherten Daten setzen
    const meldungId = this.route.snapshot.paramMap.get('id');
    this.backendService.getMeldung(meldungId)
      .subscribe( meldung => this.showMeldung(meldung) );
    this.route.queryParams
      .subscribe( params => {
        let state = window.history.state;
        this.showCreatedMeldung = 'created' in params || (state && state.created);
      });
    this.date = new Date();
  }

  downloadPdf(): void {
    this.backendService.getMeldungPdf(this.meldung.id)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  downloadBewilligung(): void {
    this.backendService.downloadBewilligung(this.meldung.id)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  showMeldung(meldung: Meldung): void {
    this.meldung = meldung;
    this.stornoDisabled = this.meldung.status.id === StatusId.STORNIERT ||
        this.meldung.status.id === StatusId.STORNO_ANFRAGE && this.authorizationService.isTierarzt();
    this.showBewilligeMeldung = this.meldung.status.id === StatusId.OFFEN && this.authorizationService.isBlv();
  }

  deactivateMeldung(): void {
    Swal.fire({
      title: this.translateService.instant('meldung.view.deaktivieren.title'),
      html: this.deaktivierenDialog.nativeElement,
      width: 'auto',
      showConfirmButton: false
    }).then((result) =>console.log(result));
  }

  bewilligeMeldung(): void {
    Swal.fire({
      title: this.translateService.instant('meldung.view.bewilligen.title'),
      html: this.bewilligenDialog.nativeElement,
      width: 'auto',
      showConfirmButton: false
    }).then((result) => console.log(result));
  }

  editNotiz(): void {
    Swal.fire({
      html: this.notizEdit.nativeElement,
      width: 'auto',
      showConfirmButton: false
    }).then((result) =>console.log(result));
  }

  get showAuslaendischeZulassungIndikation(): boolean {
    return this.meldung.praeparat.auslaendischeZulassungTierart === true;
  }

  get showImmunologischFelder(): boolean {
    return this.meldung.art.id > MeldungArtId.ALPHA && this.meldung.praeparat.immunologisch === true;
  }

  get showNotizBlv(): boolean {
    return (this.meldung.notiz?.notizBlv || this.meldung.notiz?.offeneAnfrage) && this.authorizationService.isBlv()
  }

  get isStorniert(): boolean {
    return this.meldung.status.id === StatusId.STORNIERT;
  }

  get isExtendedPdf(): boolean {
    return this.meldung.requiredDokumente.bewilligungVorhanden;
  }

  get isStornoAnfrage(): boolean {
    return this.meldung.status.id === StatusId.STORNO_ANFRAGE;
  }

  get showAlertBewilligungsPflichtig(): boolean {
    let zulassungsLand = this.meldung.praeparat.zulassungsLand;
    let nutzung = this.meldung.praeparat.nutzung;

    if (this.meldung.art.id < MeldungArtId.BETA) {
      return (this.showAuslaendischeZulassungIndikation && this.meldung.praeparat.auslaendischeZulassungIndikation !== true)
        || (this.meldung.praeparat.auslaendischeZulassungTierart !== true
        || this.meldung.praeparat.anhang5 === true
        || this.meldung.praeparat.bienen === true
        || (zulassungsLand && nutzung && !zulassungsLand.erlaubteNutzung.includes(nutzung.id)))
        || this.meldung.praeparat.immunologisch === true;
    }

    return (this.meldung.praeparat.umgewidmet === true && nutzung.id === NutzungId.NUTZTIER)
      || this.meldung.praeparat.anhang5 === true
      || this.meldung.praeparat.bienen === true
      || (zulassungsLand && nutzung && !zulassungsLand.erlaubteNutzung.includes(nutzung.id))
      || this.meldung.praeparat.immunologisch === true;
  }

  get bestaetigungCode(): string {
    return this.meldung.art?.id < MeldungArtId.GAMMA
      ? 'meldung.feld.bestaetigung.beta'
      : 'meldung.feld.bestaetigung.gamma';
  }

  canDeactivate(): boolean | Promise<boolean> {
    if (this.preventRedirect) {
      return Swal.fire({
        text: this.translateService.instant('dokument.unsaved.message'),
        icon: 'warning',
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('global.yes'),
        cancelButtonText: this.translateService.instant('global.no')
      }).then( (result) => result.isConfirmed );
    }
    return true;
  };

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: BeforeUnloadEvent): void {
    if (this.preventRedirect) {
      event.preventDefault();
      event.returnValue = this.translateService.instant('dokument.unsaved.message');
    }
  }

    protected readonly MeldungArtId = MeldungArtId;
}
