<div class="row admin">
  <p class="admin"><b>{{ 'admin.settings.new' | translate }}</b></p>
</div>
<div class="row admin">
    <div class="col-md-2 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.settings.column.key' | translate }}</b></p></div>
    <div class="col-md-3 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.settings.column.value' | translate }}</b></p></div>
    <div class="col-md-3 pl-0 mb-0"><p class="admin mt-0"><b>{{ 'admin.settings.column.description' | translate }}</b></p></div>
</div>
<div class="row admin">
  <form [formGroup]="newSettingForm" (ngSubmit)="onSubmit()">
    <div class="col-md-2 pl-0 mb-0">
      <input id="key_new" class="admin-settings" formControlName="key_new" maxlength="255">
    </div>

    <div class="col-md-3 pl-0 mb-0">
      <input id="value_new" class="admin-settings" formControlName="value_new" maxlength="255">
    </div>

    <div class="col-md-3 pl-0 mb-0">
      <input id="description_new" class="admin-settings" formControlName="description_new" maxlength="255">
    </div>

    <div class="col-md-2 col-md-offset-2 pl-0 mb-0">
      <button class="btn btn-sm admin-settings" type="submit" [disabled]="newSettingForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" [title]="'global.cancel' | translate" (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </form>
</div>
