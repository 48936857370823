import { Component, OnInit } from '@angular/core';
import { ImportGrundAdmin } from '@app/shared/classes/import-grund';
import { BackendService } from '@app/shared/services/backend.service';

@Component({
  selector: 'app-admin-stammdaten-importgrund',
  templateUrl: './admin-stammdaten-importgrund.component.html'
})
export class AdminStammdatenImportgrundComponent implements OnInit {
  gruende: ImportGrundAdmin[] = [];
  setListError = false;

  constructor(private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.backendService.listImportGruendeAdmin()
      .subscribe({
        next: result => {
          this.gruende = result;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.error(error);
        }
      });
  }

  onGrundDeleted(index: number) {
    this.gruende.splice(index, 1);
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
