import { DokumentTyp } from "./dokument-typ";

export interface DokumentJSON {
  id: number;
  temporary: boolean;
  typ: DokumentTyp;
  name: string;
  highlight: string;
}

export class Dokument {
  id: number;
  temporary: boolean;
  typ: DokumentTyp;
  name: string;
  highlight: string;

  public constructor(init?: Partial<Dokument>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): Dokument {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const dokument = Object.create(Dokument.prototype);
    return Object.assign(dokument, object);
  }

  toJSON(): DokumentJSON {
    return Object.assign({}, this);
  }
}
