export interface ImportGrundJSON {
  id: number;
  bezeichnung: string;
  kommentarPflicht: boolean;
  zulassungsNrChAnzeigen: boolean;
  lieferEngpassAnzeigen: boolean;
}

export class ImportGrund {
  id: number;
  bezeichnung: string;
  kommentarPflicht: boolean;
  zulassungsNrChAnzeigen: boolean;
  lieferEngpassAnzeigen: boolean;

  static fromJSON(json: string | object): ImportGrund {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const importGrund = Object.create(ImportGrund.prototype);
    return Object.assign(importGrund, object);
  }

  toJSON(): ImportGrundJSON {
    return Object.assign({}, this);
  }
}

export interface ImportGrundAdminJSON {
  id?: number;
  bezeichnungDe: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  sortierung: number;
  kommentarPflicht: boolean;
  zulassungsNrChAnzeigen: boolean;
  lieferEngpassAnzeigen: boolean;
  aktiv: boolean;
}

export class ImportGrundAdmin {
  id?: number;
  bezeichnungDe: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  sortierung: number;
  kommentarPflicht: boolean;
  zulassungsNrChAnzeigen: boolean;
  lieferEngpassAnzeigen: boolean;
  aktiv: boolean;

  static fromJSON(json: string | object): ImportGrundAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const importGrund = Object.create(ImportGrundAdmin.prototype);
    return Object.assign(importGrund, object);
  }

  toJSON(): ImportGrundAdminJSON {
    return Object.assign({}, this);
  }
}
