import { Component, EventEmitter, Input, Output } from "@angular/core";
import Swal from "sweetalert2";
import { BackendService } from "@app/shared/services/backend.service";
import { Meldung } from "@app/shared/classes/meldung";
import { DeaktivierungsGrund } from '@app/shared/classes/deaktivierungs-grund';
import { MeldungStorno } from '@app/shared/classes/meldung-storno';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '@app/core/auth/authorization.service';

@Component({
  selector: 'meldung-deaktivieren-dialog',
  templateUrl: './meldung-deaktivieren-dialog.component.html'
})
export class MeldungDeaktivierenDialogComponent {

  id: number;
  label: string;
  gruende: DeaktivierungsGrund[];
  kommentar: string;
  error: string;

  constructor(private authorizationService: AuthorizationService,
              private backendService: BackendService,
              private translateService: TranslateService) {

    this.label = this.authorizationService.isTierarzt()
           ? 'meldung.view.deaktivieren.grund.request'
           : 'meldung.view.deaktivieren.grund.label';
  }

  @Output() result = new EventEmitter<Meldung>();

  @Input()
  set meldung(value: Meldung) {
    this.id = value?.id;
    this.error = null;
    if (value && this.authorizationService.isBlv()) {
      this.backendService.listDeaktivierungsGruende()
        .subscribe( gruende => {
          gruende.forEach( it => {
            if (it.text.includes('{{ANFRAGE}}')) {
              it.text = it.text.replace('{{ANFRAGE}}', value.stornoKommentar ?? '');
              if (value.stornoKommentar && !this.kommentar) {
                this.kommentar = it.text;
              }
            }
          });
          this.gruende = gruende;
        });
    }
  }

  cancel(): void {
    Swal.close();
  }

  save(): void {
    if (!this.kommentar || this.kommentar.length < 10) {
      this.error = this.translateService.instant('meldung.view.deaktivieren.grund.tooshort');
      return;
    }
    this.error = null;

    const storno = new MeldungStorno();
    storno.id = this.id;
    storno.kommentar = this.kommentar;

    this.backendService.stornoMeldung(storno)
      .subscribe( meldung => {
        Swal.close();
        this.result.emit(meldung)
      });
  }
}
