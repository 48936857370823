export class I18nParser {
  private readonly MATCHER_NEW_LINE = /.{0,2}\\n.{0,2}/g;
  private readonly MATCHER_ESCAPED_NEW_LINE = /( {2}|\\n|\\\\)\\n|\\n{2}/;

  private values: Map<string, string> = new Map<string, string>();
  warnings: string[] = [];

  constructor(data: any) {
    this.addData(data);
  }

  private addData(data: any, prefix?: string) {
    if (typeof data === 'object') {
      prefix = prefix ? `${prefix}.` : '';
      for (const key in data) {
        this.addData(data[key], prefix + key);
      }
    } else if(prefix) {
      let value = data?.toString();
      value = value?.replace(/\\/g, '\\\\');
      value = value?.replace(/\r?\n/g, '\\n');
      value = value?.replace(/\r?\n/g, '\\n');
      value = value?.replace(/ (?=[.:!?])/g, '\u00A0');
      value = value?.replace(/\bn° /g, 'n°\u00A0');
      if (this.containsSimpleNewLine(value)) {
        this.warnings.push(`'${prefix}' enthält einfache Zeilenumbrüche`)
      }
      this.values.set(prefix, value);
    }
  }

  private containsSimpleNewLine(value: string) {
    // Workaround, da Safari erst seit kurzem mit Look-Behind umgehen kann:
    return value.match(this.MATCHER_NEW_LINE)?.find( match =>
      !this.MATCHER_ESCAPED_NEW_LINE.test(match)
    );
  }

  getJson(key: string): string {
    return this.values.get(key) || '';
  }

  getProperties(key: string): string {
    let value = this.values.get(key);
    value = value?.replace(/(?=[:!])/g, '\\');
    if (/\{\d+}/.test(value)) {
      value = value.replace(/'/g, "''");
    }
    return value || '';
  }
}
