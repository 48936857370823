
<textarea autocomplete="off" aria-required="true"
          class="form-control textarea form_field form_field_textarea"
          rows="4" [(ngModel)]="notizBlv"></textarea>
<div class="checkbox checkbox--left">
  <label for="offeneAnfrage">
    <input type="checkbox" id="offeneAnfrage" [(ngModel)]="offeneAnfrage">
    <span>{{ "meldung.view.notiz.edit.offeneAnfrage" | translate }}</span>
  </label>
</div>
<button class="btn btn-delete" (click)="delete()">
  <span class="glyphicon glyphicon-trash"></span>
</button>
<button class="btn btn-primary ml-15" (click)="save()">{{ "global.ok" | translate }}</button>
