import { ChangeDetectorRef, Directive, ElementRef, HostBinding, HostListener, NgZone, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective implements OnDestroy {

  @HostBinding('class.open')
  isOpen = false;

  constructor(private elementRef: ElementRef,
              private ngZone: NgZone,
              private changeDetector: ChangeDetectorRef) {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
  }

  ngOnDestroy(): void {
    this.unbindEvents();
  }

  @HostListener('click')
  toggleOpen() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.bindEvents();
    } else {
      this.unbindEvents();
    }
  }

  private hide() {
    this.isOpen = false;
    this.unbindEvents();

    // since we are handling events without going through Zone.js, automatic change detection will not happen
    this.changeDetector.detectChanges();
  }

  private bindEvents() {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('click', this.onDocumentClick);
      document.addEventListener('keydown', this.onKeydown);
    });
  }

  private unbindEvents() {
    this.ngZone.runOutsideAngular(() => {
      document.removeEventListener('click', this.onDocumentClick);
      document.removeEventListener('keydown', this.onKeydown);
    });
  }

  private onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Check if the click was outside the element
    if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
      this.hide();
    }
  }

  private onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.hide();
    }
  }
}
