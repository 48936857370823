import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Settings } from '@app/core/config/settings';
import { BackendService } from '@app/shared/services/backend.service';
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-admin-settings-new',
  templateUrl: './admin-settings-new.component.html'
})
export class AdminSettingsNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();

  newSettingForm: UntypedFormGroup;
  isLoading = false;

  setting: Settings = new Settings();

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.newSettingForm = this.formBuilder.group({
      key_new: ['', Validators.required],
      value_new: ['', Validators.required],
      description_new: ['']
    });
  }

  onSubmit() {
    const formValues = this.newSettingForm.value;
    this.setting.key = formValues.key_new;
    this.setting.value = formValues.value_new;
    this.setting.description = formValues.description_new;
    this.backendService.createSetting(this.setting)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          this.showAlert();
          console.error(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  showAlert() {
    Swal.fire({
      text: this.translateService.instant('admin.settings.save.error'),
      icon: 'error',
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
