<ng-template [ngIf]="!preview" [ngIfElse]="previewTemplate">
  <div class="mb-15" *ngIf="tierarztSprache" [innerText]="tierarztSprache"></div>

  <div class="btn-group mb-15" role="group">
    <button type="button" class="btn" [class]="sprache === 'DE' ? 'btn-primary' : 'btn-default'" (click)="sprache = 'DE'">{{ 'global.sprache.de' | translate }}</button>
    <button type="button" class="btn" [class]="sprache === 'FR' ? 'btn-primary' : 'btn-default'" (click)="sprache = 'FR'">{{ 'global.sprache.fr' | translate }}</button>
    <button type="button" class="btn" [class]="sprache === 'IT' ? 'btn-primary' : 'btn-default'" (click)="sprache = 'IT'">{{ 'global.sprache.it' | translate }}</button>
  </div>

  <div class="mb-15">{{ 'meldung.view.bewilligen.kommentar' | translate }}</div>
  <textarea autocomplete="off" aria-required="true"
            class="form-control textarea form_field form_field_textarea mt-15"
            rows="4" [(ngModel)]="kommentar"></textarea>

  <button class="btn btn-default mt-15" (click)="cancel()">{{ "global.cancel" | translate }}</button>
  <button class="btn btn-primary mt-15 ml-15" (click)="openPreview()" [disabled]="requestPending">{{ "meldung.view.bewilligen.preview" | translate }}</button>
</ng-template>
<ng-template #previewTemplate>
  <div>
    <button class="btn btn-default btn-sm" [disabled]="page === 0" (click)="page = page - 1">
      <i class="glyphicon glyphicon-triangle-left"></i>
    </button>
    <span class="ml-15 mr-15">Seite {{page + 1}} / {{preview.length}}</span>
    <button class="btn btn-default btn-sm" [disabled]="page === (preview.length - 1)" (click)="page = page + 1">
      <i class="glyphicon glyphicon-triangle-right"></i>
    </button>
  </div>
  <div><img [src]="preview[page]" alt="Vorschau - Seite {{page}}"/></div>
  <div>
    <button class="btn btn-default btn-sm" [disabled]="page === 0" (click)="page = page - 1">
      <i class="glyphicon glyphicon-triangle-left"></i>
    </button>
    <span class="ml-15 mr-15">Seite {{page + 1}} / {{preview.length}}</span>
    <button class="btn btn-default btn-sm" [disabled]="page === (preview.length - 1)" (click)="page = page + 1">
      <i class="glyphicon glyphicon-triangle-right"></i>
    </button>
  </div>
  <button class="btn btn-default mt-15" (click)="preview = null" [disabled]="requestPending">{{ "global.back" | translate }}</button>
  <button class="btn btn-primary mt-15 ml-15" (click)="save()" [disabled]="requestPending">{{ "meldung.view.bewilligen.label" | translate }}</button>
</ng-template>
