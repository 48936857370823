import { TemplateType } from './template-type';

export type TemplateListChangedMap = {
  DE: { [typeId: number]: string},
  FR: { [typeId: number]: string}
  IT: { [typeId: number]: string}
};

export interface TemplateListJSON {
  types: TemplateType[];
  changed: TemplateListChangedMap;
}

export class TemplateList {
  types: TemplateType[];
  changed: TemplateListChangedMap;

  static fromJSON(json: string | object): TemplateList {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const list = Object.create(TemplateList.prototype);
    return Object.assign(list, object);
  }

  toJSON(): TemplateListJSON {
    return Object.assign({}, this);
  }
}
