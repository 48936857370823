<header class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <button appBackButton type="button" class="btn btn-sm btn-primary back-button"
                [title]="'global.back' | translate"
                [attr.aria-label]="'global.back' | translate">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </div>
  </div>
</header>

<section class="contribute inverted-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 errors">
        <h1>{{ 'global.unauthorized' | translate }}</h1>
      </div>
    </div>
  </div>
</section>

