<div class="container admin">
  <h1>{{ 'admin.i18n.poeditor.title' | translate }}</h1>
  <div class="row">
    <div class="col-sm-2">
      <button class="btn btn-default" (click)="chooseFile()">
        {{ 'admin.i18n.poeditor.import' | translate }}
      </button>
      <input class="hidden" type="file" accept=".json" #fileInput (change)="importFile($event)"/>
    </div>
    <div class="col-sm-4">
      <textarea readonly [ngModel]="json" class="form-control" rows="10"></textarea>
    </div>
    <div class="col-sm-4">
      <textarea readonly [ngModel]="properties" class="form-control" rows="10"></textarea>
    </div>
  </div>
  <div class="row" *ngIf="warnings.length > 0">
    <div class="col-sm-12">
      <ul>
        <li *ngFor="let warning of warnings" class="red">{{ warning }}</li>
      </ul>
    </div>
  </div>
</div>
