import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '@app/shared/services/backend.service';
import { TabChangeEvent } from '@app/shared/tabs/tabs.component';
import { downloadFile } from "@app/shared/download-utils";
import packageJson from '../../../package.json';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  backendVersion: string;

  selectedIterations: any = 1;
  selectedLogPeriod: any = 1;
  currentCategory: string;

  constructor(private backendService: BackendService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.backendService.getVersion()
      .subscribe(result => {
        if (/^\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(result)) {
          this.backendVersion = result;
        } else {
          this.backendVersion = 'N/A';
        }
      });

    this.route.queryParams.subscribe(() => {
      this.currentCategory = this.route.snapshot.params.category || 'job';
    });
  }

  getLogFile() {
    const days = this.selectedLogPeriod;
    this.backendService.getLog(days)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  onCategoryChange(event: TabChangeEvent) {
    this.router.navigate(['/admin', event.tabId], { queryParamsHandling: 'preserve' });
  }

  get getFrontendVersion() { return packageJson.version; }
  get getBackendVersion() { return (this.backendVersion) ? this.backendVersion : '-'; }
}
