<div class="row admin">
  <div class="col-md-6">
    <p class="admin">{{ 'admin.check.info' | translate }}</p>
  </div>
  <div class="col-md-2">
    <button (click)="checkShort()" class="btn btn-sm btn-default">
      <i class="fa fa-play fa-fw" [ngClass]="checkRunning?'fa-spinner fa-pulse':'fa-play'"></i>
    </button>
  </div>
  <div class="col-md-1">
    <i *ngIf="checkOk" class="fa fa-check job-ok"></i>
    <i *ngIf="!checkOk" class="fa fa-times job-nok"></i>
  </div>
</div>
<hr/>
<div class="row admin">
  <div class="col-md-1">
    <p class="admin-checks title">{{ 'admin.check.column.status' | translate }}</p>
  </div>
  <div class="col-md-3">
    <p class="admin-checks title">{{ 'admin.check.column.job' | translate }}</p>
  </div>
  <div class="col-md-4">
    <p class="admin-checks title">{{ 'admin.check.column.finished' | translate }}</p>
  </div>
  <div class="col-md-4">
    <p class="admin-checks title">{{ 'admin.check.column.exception' | translate }}</p>
  </div>
</div>
