<form [formGroup]="grundForm" (ngSubmit)="onSubmit()">
  <div class="row admin">
    <div class="hidden">
      <input id="id" class="admin-settings" formControlName="id" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-sm-1 pl-0 mb-0">
      <input id="sortierung" class="admin-settings" formControlName="sortierung" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <input id="bezeichnungDe" class="admin-settings" formControlName="bezeichnungDe" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <input id="bezeichnungFr" class="admin-settings" formControlName="bezeichnungFr" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <input id="bezeichnungIt" class="admin-settings" formControlName="bezeichnungIt" [ngClass]="delChosen?'setting-delete':''">
    </div>

    <div class="col-sm-2 pl-0 mb-0">
      <button class="btn btn-sm admin-settings" type="submit"
              [ngStyle]="{ 'visibility': grundForm.dirty ? '' : 'hidden'}"
              [disabled]="grundForm.invalid">
        <i class="fa fa-check"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" [title]="'global.cancel' | translate"
              [ngStyle]="{ 'visibility': grundForm.dirty ? '' : 'hidden'}"
              (click)="onReset()">
        <i class="fa fa-times"></i>
      </button>

      <button class="btn btn-sm admin-settings" type="button" [title]="'global.delete' | translate"
              (click)="deleteGrund(grund.id)">
        <i class="fa fa-trash-o"></i>
      </button>
    </div>
  </div>
  <div class="row admin mb-15">
    <div class="col-sm-3 pl-0 mb-0 col-sm-offset-1">
      <textarea id="textDe" class="admin-settings" formControlName="textDe"></textarea>
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <textarea id="textFr" class="admin-settings" formControlName="textFr"></textarea>
    </div>

    <div class="col-sm-3 pl-0 mb-0">
      <textarea id="textIt" class="admin-settings" formControlName="textIt"></textarea>
    </div>
  </div>
</form>
