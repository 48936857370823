export interface NotizBlvJSON {
  id: number;
  offeneAnfrage: boolean;
  notizBlv: string;
}

export class NotizBlv {
  id: number;
  offeneAnfrage: boolean;
  notizBlv: string;

  public constructor(init?: Partial<NotizBlv>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): NotizBlv {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const notiz = Object.create(NotizBlv.prototype);
    return Object.assign(notiz, object);
  }

  toJSON(): NotizBlvJSON {
    return Object.assign({}, this);
  }
}
