<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">{{ 'admin.error' | translate }}</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <div class="row admin">
      <p class="admin-warning"><b>{{ 'admin.warning' | translate }}</b></p>
    </div>
    <div class="row admin">
      <div class="col-sm-4 pl-0 mb-0"><p class="admin"><b>&nbsp;</b></p></div>
      <div class="col-sm-2.5 pl-0 mb-0" *ngFor="let sprache of sprachen"><p class="admin"><b>{{ sprache }}</b></p></div>
    </div>
    <div class="row admin" *ngFor="let type of types">
      <div class="col-sm-4 pl-0 mb-0"><p class="admin"><b>{{ type.messageProperty | translate }}</b></p></div>
      <div class="col-sm-2.5 pl-0 mb-0" *ngFor="let sprache of sprachen">
        <p class="admin">
          <input class="hidden" type="file" accept=".docx" (change)="upload(type, sprache, $event)" #fileInput>
          <button class="btn btn-default" (click)="fileInput.click()"
                  matTooltipPosition="left" [matTooltip]="'admin.template.upload.tooltip' | translate">
            <i class="glyphicon glyphicon-arrow-up"></i>
          </button>
          <button class="btn btn-default ml-15" (click)="download(type, sprache)" [disabled]="!changed[sprache][type.id]"
                  matTooltipPosition="right" matTooltipClass="line-break" [matTooltip]="downloadTooltip(type, sprache)">
            <i class="glyphicon glyphicon-arrow-down"></i>
          </button>
        </p>
      </div>
    </div>
    <div class="row admin" *ngIf="uploadSuccess">
      <p class="admin-success"><b>{{ 'admin.template.upload.success' | translate:{file:uploadSuccess} }}</b></p>
    </div>
    <div class="row admin" *ngIf="uploadError">
      <p class="admin-warning"><b>{{ 'admin.template.upload.error' | translate:{file:uploadError} }}</b></p>
    </div>
    <div class="row admin" *ngIf="downloadError">
      <p class="admin-warning"><b>{{ 'admin.template.download.error' | translate:{file:downloadError} }}</b></p>
    </div>
    <div class="row admin mt-50">
      <button class="btn btn-default btn-sm" (click)="downloadDummy()"
              matTooltipPosition="right" [matTooltip]="'admin.template.dummy.tooltip' | translate">
        {{ 'admin.template.dummy.label' | translate }}
      </button>
    </div>
  </ng-template>
</div>
