export interface DokumentTypJSON {
  id: number;
  messageProperty: string;
  maxUploadCount: number;
  canDelete: boolean;
  canMarkUnread: boolean;
}

export class DokumentTyp {
  id: number;
  messageProperty: string;
  maxUploadCount: number;
  canDelete: boolean;
  canMarkUnread: boolean;

  static fromJSON(json: string | object): DokumentTyp {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const dokumentTyp = Object.create(DokumentTyp.prototype);
    return Object.assign(dokumentTyp, object);
  }

  toJSON(): DokumentTypJSON {
    return Object.assign({}, this);
  }
}

export enum DokumentTypId {
  DETAILHANDELS_BEWILLIGUNG_KANTON = 20,
  BESTAETIGUNG_LIEFERENGPASS = 40,
  BETAEUBUNGSMITTEL_BEWILLIGUNG_SWISSMEDIC = 50,
  LABORANALYSEN_AUTOVAKZINE = 70,
  BEWILLIGUNG_BLV = 90
}
