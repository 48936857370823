import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from '@app/core/auth/authentication.service';
import { Login } from '@app/core/auth/login';
import { BackendService } from '../shared/services/backend.service';
import { Meldung } from '@app/shared/classes/meldung';
import { CryptoService } from '@app/shared/services/crypto.service';
import { ImportGrund } from '@app/shared/classes/import-grund';
import { Packung } from '@app/shared/classes/packung';
import { Land } from '@app/shared/classes/land';
import { DokumentTyp } from "@app/shared/classes/dokument-typ";
import { Dokument } from "@app/shared/classes/dokument";
import { Nutzung, NutzungId } from "@app/shared/classes/nutzung";
import { DokumentTypSelector } from "@app/meldung-new/classes/dokument-typ-selector";

@Component({
  selector: 'app-meldung-new',
  templateUrl: './meldung-new.component.html'
})

export class MeldungNewComponent implements OnInit {
  login: Login;
  meldungForm: UntypedFormGroup;
  meldungFormSubmitted: boolean = false;
  meldung: Meldung;
  storedMeldung: Meldung = null;
  _dokumente: Dokument[] = [];

  importGruende: ImportGrund[] = [];
  zulassungsLaender: Land[] = [];
  importLaender: Land[] = [];
  nutzungen: Nutzung[] = [];

  dokumentTypSelector: DokumentTypSelector;

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private authenticationService: AuthenticationService,
              private backendService: BackendService,
              private cryptoService: CryptoService,
              private route: ActivatedRoute,
              private el: ElementRef) {
    this.meldung = new Meldung();
    this.login = authenticationService.getUser();
    //TODO Anschrift überprüfen wenn Token vollständig geliefert wird
    this.meldung.praxis = this.login.anschrift;
    let meldungCiphertext = localStorage.getItem('meldung');
    if (meldungCiphertext) {
      this.cryptoService.decrypt(meldungCiphertext).then(meldungPlaintext => {
        this.storedMeldung = Meldung.fromJSON(meldungPlaintext);
      });
    }
    this.route.queryParams.subscribe(params => {
      const copyId = params.copy;
      if (copyId) {
        this.backendService.getMeldung(copyId)
          .subscribe(original => {
            original.id = null;
            original.praxis = this.meldung.praxis;
            original.praeparat.packungen?.forEach( it => it.anzahl = null );
            this.meldung = original;
            this.initForm();
            this.backendService.listDokumente(copyId, true)
              .subscribe( dokumente => this._dokumente = dokumente );
          });
      }
    });
  }

  ngOnInit(): void {
    this.backendService.listZulassungsLaender()
      .subscribe( result => {
        this.zulassungsLaender = result;
      });
    this.backendService.listImportLaender()
      .subscribe( result => {
        this.importLaender = result;
      });
    this.backendService.listNutzung()
      .subscribe( result => {
        this.nutzungen = result;
      });
    this.backendService.listImportGruende()
      .subscribe( result => {
        this.importGruende = result;
      });
    this.backendService.listDokumentTypen()
      .subscribe( result => {
        this.dokumentTypSelector = new DokumentTypSelector(result);
      });
    this.initForm();
  }

  private initForm() {
    let packungArray: UntypedFormGroup[] = [];
    this.meldung.praeparat.packungen.forEach( packung => {
      packungArray.push(this.buildPackung(packung.groesse, packung.anzahl));
    });
    if (packungArray.length === 0) {
      packungArray.push(this.buildPackung());
    }

    this.meldungForm = this.formBuilder.group({
      praxis: [this.meldung.praxis, Validators.required],
      vorname: [this.meldung.vorname, Validators.required],
      nachname: [this.meldung.nachname, Validators.required],
      email: [this.meldung.email, Validators.compose([Validators.email, Validators.required])],
      telefon: [this.meldung.telefon], // TODO: Tel-Nr Regex Validator?
      grund: [this.meldung.grund?.id, Validators.required],
      grundKommentar: [this.meldung.grundKommentar],
      importLand: [this.meldung.importLand?.id, Validators.required],
      haendlerAusland: [this.meldung.haendlerAusland],
      hauptIndikation: [this.meldung.hauptIndikation, Validators.required],
      kommentar: [this.meldung.kommentar],
      nutzung: [this.meldung.praeparat.nutzung?.id, Validators.required],
      spezies: [this.meldung.praeparat.spezies, Validators.required],
      zulassungsLand: [this.meldung.praeparat.zulassungsLand?.id, Validators.required],
      zulassungsNr: [this.meldung.praeparat.zulassungsNr, Validators.required],
      praeparat: [this.meldung.praeparat.name, Validators.required],
      zulassungsNrCh: [this.meldung.praeparat.zulassungsNrCh],
      anhang5: [this.meldung.praeparat.anhang5, Validators.required],
      umgewidmet: [this.meldung.praeparat.umgewidmet, Validators.required],
      bienen: [this.meldung.praeparat.bienen, Validators.required],
      immunologisch: [this.meldung.praeparat.immunologisch, Validators.required],
      hersteller: [this.meldung.praeparat.hersteller],
      bestandteile: [this.meldung.praeparat.bestandteile],
      tierhalter: [this.meldung.praeparat.tierhalter],
      packungen: this.formBuilder.array(packungArray),
      bestaetigung: [false, Validators.requiredTrue]
    }, {validators: this.customFormValidator});
  }

  autosave(): void {
    this.meldung = this.convertFormToMeldung();
    let meldungPlaintext = JSON.stringify(this.meldung.toJSON());
    this.cryptoService.encrypt(meldungPlaintext).then(meldungCiphertext => {
      localStorage.setItem('meldung', meldungCiphertext);
    });
    this.storedMeldung = null;
  }

  restoreAutosave(): void {
    if (this.storedMeldung) {
      this.meldung = this.storedMeldung;
      this.storedMeldung = null;
      this.backendService.getDokumente(this.meldung.neueDokumente)
        .subscribe( dokumente => this._dokumente = dokumente );
      this.initForm();
    }
  }

  get packungen(): UntypedFormArray {
    return this.meldungForm.get('packungen') as UntypedFormArray;
  }

  buildPackung(groesse ?: string, anzahl ?: number): UntypedFormGroup {
    return this.formBuilder.group({
        groesse: [groesse, Validators.required],
        anzahl: [anzahl, Validators.compose([
          Validators.required, Validators.pattern(/^(?!0+$)\d+$/)
        ])]
      })
  }

  addPackung(): void {

    if (this.packungen.length < 5) {
      this.packungen.push(this.buildPackung());
    }
  }

  removePackung(): void {

    if (this.packungen.length > 1) {
      this.packungen.removeAt(this.packungen.length - 1);
    }
  }

  trackByIndex(index: any): any {
    return index;
  }

  hasError(field: string): boolean {
    return this.meldungFormSubmitted &&
      this.meldungForm.controls[field].invalid;
  }

  send(): void {
    if (this.meldungForm.valid) {
      //TODO: Show Spinner?
      // TODO this.meldung an Backend schicken und Meldung in Localstorage mit Antwort überschreiben
      this.meldung = this.convertFormToMeldung();
      this.backendService.createMeldung(this.meldung)
        .subscribe(meldungId => {
          localStorage.removeItem('meldung');
          this.router.navigate(['meldung', meldungId], {state: {created: true}});
        });
    } else {
      Object.keys(this.meldungForm.controls).forEach(field => {
        const control = this.meldungForm.get(field);
        control.markAsDirty({onlySelf: true});
      });
      this.meldungFormSubmitted = true;
      this.focusFirstInvalidField();
    }
  }

  private focusFirstInvalidField(): void {
    // Quelle: https://medium.com/javascript-everyday/how-to-scroll-to-first-invalid-control-once-a-form-has-been-submitted-eb47d9fbc6e
    this.el.nativeElement.querySelector('form .ng-invalid')?.focus();
  }

  convertFormToMeldung(): Meldung {
    const formValues = this.meldungForm.value;

    const meldung = new Meldung();
    meldung.uid = formValues.uid;
    meldung.praxis = formValues.praxis;
    meldung.vorname = formValues.vorname;
    meldung.nachname = formValues.nachname;
    meldung.email = formValues.email;
    meldung.telefon = formValues.telefon;
    meldung.grund = this.importGruende.find( it => it.id == formValues.grund );
    meldung.grundKommentar = formValues.grundKommentar;
    meldung.importLand = this.importLaender.find( it => it.id == formValues.importLand );
    meldung.haendlerAusland = formValues.haendlerAusland;
    meldung.hauptIndikation = formValues.hauptIndikation;
    meldung.kommentar = formValues.kommentar;

    meldung.praeparat.nutzung = this.nutzungen.find( it => it.id == formValues.nutzung );
    meldung.praeparat.spezies = formValues.spezies;
    meldung.praeparat.zulassungsLand = this.zulassungsLaender.find( it => it.id == formValues.zulassungsLand );
    meldung.praeparat.zulassungsNr = formValues.zulassungsNr;
    meldung.praeparat.name = formValues.praeparat;
    meldung.praeparat.zulassungsNrCh = formValues.zulassungsNrCh;
    meldung.praeparat.anhang5 = formValues.anhang5;
    meldung.praeparat.umgewidmet = formValues.umgewidmet;
    meldung.praeparat.bienen = formValues.bienen;
    meldung.praeparat.immunologisch = formValues.immunologisch;
    meldung.praeparat.hersteller = formValues.hersteller;
    meldung.praeparat.bestandteile = formValues.bestandteile;
    meldung.praeparat.tierhalter = formValues.tierhalter;
    meldung.praeparat.packungen = [];
    formValues.packungen.forEach( formPackungValues => {
      const packung = new Packung();
      packung.groesse = formPackungValues.groesse;
      packung.anzahl = formPackungValues.anzahl;
      meldung.praeparat.packungen.push(packung);
    });
    meldung.neueDokumente = this._dokumente.map( it => it.id );

    return meldung;
  }

  get isGrundKommentarPflicht(): boolean {
    const grundId = this.meldungForm?.controls.grund.value;
    const grund = this.importGruende?.find( it => it.id == grundId );
    return grund?.kommentarPflicht;
  }

  get showZulassungsNrCh(): boolean {
    const grundId = this.meldungForm?.controls.grund.value;
    const grund = this.importGruende?.find( it => it.id == grundId );
    return grund?.zulassungsNrChAnzeigen;
  }

  get showLieferEngpass(): boolean {
    const grundId = this.meldungForm?.controls.grund.value;
    const grund = this.importGruende?.find( it => it.id == grundId );
    return grund?.lieferEngpassAnzeigen;
  }

  get showImmunologischFelder(): boolean {
    return this.meldungForm?.controls.immunologisch.value === true;
  }

  get showAlertBewilligungsPflichtig(): boolean {
    let zulassungsLand = this.zulassungsLaender.find( it => it.id == this.meldungForm.value.zulassungsLand );
    let nutzung = this.nutzungen.find( it => it.id == this.meldungForm.value.nutzung );

    return (this.meldungForm.controls.umgewidmet.value === true && nutzung?.id === NutzungId.NUTZTIER)
      || this.meldungForm.controls.anhang5.value === true
      || this.meldungForm.controls.bienen.value === true
      || (zulassungsLand && nutzung && !zulassungsLand.erlaubteNutzung.includes(nutzung.id))
      || this.meldungForm.controls.immunologisch.value === true;
  }

  get dokumentTypen(): DokumentTyp[] {
    return this.dokumentTypSelector?.getTypen(this.showLieferEngpass, this.showImmunologischFelder);
  }

  get dokumente(): Dokument[] {
    return this._dokumente;
  }

  set dokumente(dokumente: Dokument[]) {
    this._dokumente = dokumente;
    this.autosave();
  }

  customFormValidator: ValidatorFn = (form: UntypedFormGroup) => {
    if (this.isGrundKommentarPflicht && !form.controls["grundKommentar"].value) {
      form.controls["grundKommentar"].setErrors({required: true});
    } else {
      form.controls["grundKommentar"].setErrors(null);
    }

    if (this.showZulassungsNrCh && !form.controls["zulassungsNrCh"].value) {
      form.controls["zulassungsNrCh"].setErrors({required: true});
    } else {
      form.controls["zulassungsNrCh"].setErrors(null);
    }

    form.controls["hersteller"].setErrors(null);
    form.controls["bestandteile"].setErrors(null);
    form.controls["tierhalter"].setErrors(null);
    if (this.showImmunologischFelder) {
      if (!form.controls["hersteller"].value) {
        form.controls["hersteller"].setErrors({required: true});
      }
      if (!form.controls["bestandteile"].value) {
        form.controls["bestandteile"].setErrors({required: true});
      }
      if (!form.controls["tierhalter"].value) {
        form.controls["tierhalter"].setErrors({required: true});
      }
    }

    return null;
  }
}
