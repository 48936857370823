export interface MeldungBewilligungFilterJSON {
  dthUploaded: number;
  lepUploaded: number;
  btmUploaded: number;
  labUploaded: number;
  blvRequired: number;
  blvUploaded: number;
}

export class MeldungBewilligungFilter {
  dthUploaded: number;
  lepUploaded: number;
  btmUploaded: number;
  labUploaded: number;
  blvRequired: number;
  blvUploaded: number;

  public constructor(init?: Partial<MeldungBewilligungFilter>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): MeldungBewilligungFilter {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const bewilligung = Object.create(MeldungBewilligungFilter.prototype);
    return Object.assign(bewilligung, object);
  }

  toJSON(): MeldungBewilligungFilterJSON {
    return Object.assign({}, this);
  }

  anyAktiv(): boolean {
    return (
      this.dthUploaded > 0 ||
      this.lepUploaded > 0 ||
      this.btmUploaded > 0 ||
      this.labUploaded > 0 ||
      this.blvRequired > 0 ||
      this.blvUploaded > 0
    );
  }
}
