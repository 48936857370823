export interface LandJSON {
  id: number;
  bezeichnung: string;
  erlaubteNutzung: number[];
}

export class Land {
  id: number;
  bezeichnung: string;
  erlaubteNutzung: number[];

  static fromJSON(json: string | object): Land {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(Land.prototype);
    return Object.assign(land, object);
  }

  toJSON(): LandJSON {
    return Object.assign({}, this);
  }
}

export interface LandAdminJSON {
  id?: number;
  bezeichnungDe: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  sortierung: number;
  zulassungsLand: boolean;
  importLand: boolean;
  heimtierErlaubt: boolean;
  nutztierErlaubt: boolean;
}

export class LandAdmin {
  id?: number;
  bezeichnungDe: string;
  bezeichnungFr: string;
  bezeichnungIt: string;
  sortierung: number;
  zulassungsLand: boolean;
  importLand: boolean;
  heimtierErlaubt: boolean;
  nutztierErlaubt: boolean;

  static fromJSON(json: string | object): LandAdmin {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const land = Object.create(LandAdmin.prototype);
    return Object.assign(land, object);
  }

  toJSON(): LandAdminJSON {
    return Object.assign({}, this);
  }
}
