import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DeaktivierungsGrundAdmin } from '@app/shared/classes/deaktivierungs-grund';
import { BackendService } from '@app/shared/services/backend.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-admin-stammdaten-deaktivierungsgrund-entry',
  templateUrl: './admin-stammdaten-deaktivierungsgrund-entry.component.html'
})
export class AdminStammdatenDeaktivierungsgrundEntryComponent {
  @Output() deleted = new EventEmitter();

  grundForm: UntypedFormGroup;
  delChosen = false;

  grund: DeaktivierungsGrundAdmin;

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(grund: DeaktivierungsGrundAdmin) {
    this.grund = grund;
    this.initForm(grund);
  }

  private initForm(grund: DeaktivierungsGrundAdmin) {
    this.grundForm = this.formBuilder.group({
      id: ['', Validators.required],
      sortierung: ['', Validators.required],
      bezeichnungDe: ['', Validators.required],
      bezeichnungFr: ['', Validators.required],
      bezeichnungIt: ['', Validators.required],
      textDe: ['', Validators.required],
      textFr: ['', Validators.required],
      textIt: ['', Validators.required]
    });
    this.updateForm(grund);
  }

  private updateForm(grund: DeaktivierungsGrundAdmin) {
    this.grundForm.reset({
      id: grund.id,
      sortierung: grund.sortierung,
      bezeichnungDe: grund.bezeichnungDe,
      bezeichnungFr: grund.bezeichnungFr,
      bezeichnungIt: grund.bezeichnungIt,
      textDe: grund.textDe,
      textFr: grund.textFr,
      textIt: grund.textIt
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.grundForm.value;
    this.grund.id = formValues.id;
    this.grund.sortierung = formValues.sortierung;
    this.grund.bezeichnungDe = formValues.bezeichnungDe;
    this.grund.bezeichnungFr = formValues.bezeichnungFr;
    this.grund.bezeichnungIt = formValues.bezeichnungIt;
    this.grund.textDe = formValues.textDe;
    this.grund.textFr = formValues.textFr;
    this.grund.textIt = formValues.textIt;
    this.backendService.editDeaktivierungsGrund(this.grund)
      .subscribe({
        next: result => {
          this.grund = result;
          this.reloadEntries.emit();
        },
        error: console.error
      });
  }

  onReset() {
    this.updateForm(this.grund);
  }

  deleteGrund(grundId: number) {
    this.delChosen = true;
    Swal.fire({
      text: this.translateService.instant('admin.deaktivierungsgrund.delete.warning'),
      icon: 'warning',
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('admin.deaktivierungsgrund.delete.yes'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.continueDeleteGrund(grundId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteGrund(grundId: number) {
    this.backendService.deleteDeaktivierungsGrund(grundId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.error(error.message);
        }
      });
  }

  showAlert(ok: boolean) {
    let message = this.translateService.instant('admin.deaktivierungsgrund.delete.success');
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = this.translateService.instant('admin.deaktivierungsgrund.delete.error');
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
