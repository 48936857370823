import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private hasHistory = false;

  constructor(private router: Router, private location: Location) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      skip(1) // skip initial routing event on page load
    )
      .subscribe((event) => {
        this.hasHistory = true;
      });
  }

  back(): void {
    // Auskommentiert, da damit mehr Fälle abgedeckt werden können / mas 27.01.2021
    // if (this.hasHistory) {
      this.location.back();
    // } else {
      // this.router.navigateByUrl('/');
    // }
  }
}
