import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { OnDynamicData, OnDynamicMount } from 'ngx-dynamic-hooks';

import { TabComponent } from './tab.component';

export interface TabChangeEvent {
  tabId: string;
}

@Component({
  selector: 'app-tabs',
  template: `
    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabs" (click)="onSelectTab(tab)" data-toggle="tab" [class.autoWidth]="tab.autoWidth" [class.active]="tab.active" [attr.aria-selected]="tab.active">
        <a title="{{tab.tabTitle}}">{{tab.tabTitle}}</a>
      </li>
    </ul>
    <ng-content></ng-content>
  `
})
export class TabsComponent implements AfterContentInit, OnChanges, OnDynamicMount {

  @Input() selectedId: string;
  @Output() tabChanged = new EventEmitter<TabChangeEvent>();

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  private activeTab: TabComponent;

  onDynamicMount(data: OnDynamicData): void {
    const components = data.contentChildren.map(c => c.componentRef.instance);
    this.tabs.reset(components as TabComponent[]);
    this.preselectTab();
  }

  ngAfterContentInit() {
    this.preselectTab();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedId && !changes.selectedId.firstChange) {
      this.preselectTab();
    }
  }

  private preselectTab(): void {
    if (!this.selectedId) {
      this.selectTab(this.tabs.first);
      return;
    }

    const tab = this.findTab(this.selectedId);
    if (tab) {
      this.selectTab(tab);
      return;
    }

    this.selectTab(this.tabs.first);
  }

  onSelectTab(tab: TabComponent) {
    this.selectTab(tab);
    this.tabChanged.emit({ tabId: tab.tabId });
  }

  selectTab(tab: TabComponent) {
    if (tab === this.activeTab) {
      return;
    }

    // deactivate active tab
    if (this.activeTab) {
      this.activeTab.active = false;
    }

    // activate the tab the user has clicked on.
    this.activeTab = tab;
    tab.active = true;
  }

  private findTab(id: string): TabComponent {
    return this.tabs.find(t => t.tabId === id);
  }
}
