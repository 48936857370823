<div class="panel panel-default text-left" *ngIf="dokumente.length || unsavedDokumente.length || availableUploads.length">
  <div class="panel-heading">
    <div class="panel-title">
      {{ 'dokument.title' | translate }}
    </div>
  </div>
  <div class="panel-body" [ngClass]="{'has-error': errorMessage}">

    <div class="row">
      <div class="col-sm-12 mb-0">
        <div class="col-sm-12 mb-0">
          <p class="tam-alert mb-15" *ngIf="showAlertImmunologisch">
            <i [class]="iconAlertImmunologisch"></i>
            <span [innerHTML]="'meldung.feld.praeparat.alert.immunologisch' | translate | marked:true"></span>
          </p>
          <p class="tam-alert mb-15" *ngIf="showAlertLieferEngpass">
            <i [class]="iconAlertLieferEngpass"></i>
            <span [innerHTML]="'meldung.feld.praeparat.alert.lieferEngpass' | translate | marked:true | injectUrl:'url.lieferEngpass'"></span>
          </p>
        </div>
      </div>
    </div>

    <table *ngIf="dokumente.length || unsavedDokumente.length" class="dokument-table">
      <tr *ngFor="let dokument of dokumente" [class]="dokument.highlight">
        <td>{{ dokument.typ.messageProperty | translate }}</td>
        <td>
          <a class="cursor-pointer" (click)="downloadDokument(dokument)">
            {{ dokument.name }}
          </a>
        </td>
        <td>
          <span class="glyphicon glyphicon-trash cursor-pointer" *ngIf="canDelete(dokument)"
             [matTooltip]="'global.tooltip.dokument.loeschen' | translate" matTooltipPosition="right"
             (click)="deleteDokument(dokument)"></span>
        </td>
        <td>
          <span class="glyphicon glyphicon-eye-close cursor-pointer" *ngIf="dokument.typ.canMarkUnread && !dokument.highlight"
             [matTooltip]="'global.tooltip.dokument.ungelesen' | translate" matTooltipPosition="right"
             (click)="markUnread(dokument)"></span>
        </td>
      </tr>
      <tr *ngFor="let dokument of unsavedDokumente" [class]="dokument.highlight">
        <td>{{ dokument.typ.messageProperty | translate }}</td>
        <td>
          <a class="cursor-pointer" (click)="downloadDokument(dokument)">
            {{ dokument.name }}
          </a>
        </td>
        <td>
          <span class="glyphicon glyphicon-trash cursor-pointer" *ngIf="canDelete(dokument)"
             [matTooltip]="'global.tooltip.dokument.loeschen' | translate" matTooltipPosition="right"
             (click)="deleteDokument(dokument)"></span>
        </td>
        <td></td>
      </tr>
    </table>
    <div class="row mt-15" *ngIf="unsavedDokumente.length">
      <div class="col-sm-12 mb-0">
        <button class="btn btn-primary ml-15" (click)="saveDokumente()">
          {{ 'global.save' | translate }}
        </button>
      </div>
    </div>
    <div class="row" [ngClass]="{'mt-15': dokumente.length || unsavedDokumente.length}" *ngIf="availableUploads.length">
      <div class="col-sm-12 mb-0">
        <div class="col-sm12 mb-0">
          <input class="hidden" type="file" accept="image/*,.pdf" (change)="uploadDokument($event)" #fileInput>
          <button class="btn btn-default ml-15" *ngFor="let dokumentTyp of availableUploads" (click)="selectDokument(dokumentTyp)">
            {{ dokumentTyp.messageProperty | translate }}
          </button>
        </div>
        <div class="col-sm-12 mb-0 help-block" role="alert" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</div>
