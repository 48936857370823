export interface NutzungJSON {
  id: number;
  messageProperty: string;
}

export class Nutzung {
  id: number;
  messageProperty: string;

  static fromJSON(json: string | object): Nutzung {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const nutzung = Object.create(Nutzung.prototype);
    return Object.assign(nutzung, object);
  }

  toJSON(): NutzungJSON {
    return Object.assign({}, this);
  }
}

export enum NutzungId {
  NUTZTIER = 10,
  HEIMTIER = 50
}
