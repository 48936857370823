import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BackendService } from "@app/shared/services/backend.service";
import { I18nParser } from "@app/admin/i18n/classes/i18n-parser";

@Component({
  selector: 'app-i18n-settings',
  templateUrl: './admin-i18n.component.html'
})
export class AdminI18nComponent implements OnInit {

  private templateJson: string;
  private templateProperties: string;
  json: string;
  properties: string;
  warnings: string[] = [];
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.backendService.getI18nJsonTemplate()
      .subscribe( it => this.templateJson = it );

    this.backendService.getI18nPropertiesTemplate()
      .subscribe( it => this.templateProperties = it );
  }

  chooseFile(): void {
    this.fileInput.nativeElement.click();
  }

  importFile(event): void {
    const files: FileList = event.target.files;
    if (files.length) {
      const file = files[0];
      file.text().then( json => this.processJson(JSON.parse(json)) );
      this.fileInput.nativeElement.value = null;
    }
  }

  private processJson(json: any): void {
    let parser = new I18nParser(json);
    this.json = this.templateJson.replace(/\{\{([\w.]+)}}/g, (_, key) => parser.getJson(key));
    this.properties = this.templateProperties.replace(/\{\{([\w.]+)}}/g, (_, key) => parser.getProperties(key));
    this.warnings = parser.warnings;
  }
}
