import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BackendService } from '@app/shared/services/backend.service';
import Swal from 'sweetalert2';
import { Dokument } from "@app/shared/classes/dokument";
import { downloadFile } from "@app/shared/download-utils";
import { DokumentTyp, DokumentTypId } from "@app/shared/classes/dokument-typ";
import { forkJoin } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AuthorizationService } from "@app/core/auth/authorization.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dokument',
  templateUrl: './dokument.component.html'
})
export class DokumentComponent {

  meldungId: number;
  @ViewChild('fileInput') fileInput: ElementRef;
  dokumente: Dokument[] = [];
  unsavedDokumente: Dokument[] = [];
  dokumentTypen: DokumentTyp[] = [];
  availableUploads: DokumentTyp[] = [];
  existingUploads: DokumentTyp[] = [];
  remainingUploads: Map<DokumentTyp, number> = new Map<DokumentTyp, number>();
  selectedUpload: DokumentTyp;
  errorMessage: string;
  @Output() onDelete = new EventEmitter();
  @Output() preventRedirect = new EventEmitter<boolean>();

  constructor(private authorizationService: AuthorizationService,
              private backendService: BackendService,
              private translateService: TranslateService) {
  }

  @Input()
  set id(meldungId: number) {
    if (this.meldungId !== meldungId) {
      this.unsavedDokumente = [];
    }
    this.meldungId = meldungId;
    if (meldungId) {
      this.refresh();
    } else {
      this.dokumente = [];
      this.dokumentTypen = [];
      this.refreshRemainingUploads();
    }
  }

  refresh(): void {
    this.errorMessage = null;
    forkJoin([
      this.backendService.listDokumente(this.meldungId),
      this.backendService.listAvailableUploads(this.meldungId)
    ]).subscribe( ([dokumente, dokumentTypen]) => {
        this.dokumente = dokumente;
        this.dokumentTypen = dokumentTypen;
        this.refreshRemainingUploads();
      });
  }

  refreshRemainingUploads(): void {
    let existingTypen = [].concat(
      this.dokumente.map( it => it.typ.id ),
      this.unsavedDokumente.map( it => it.typ.id )
    );
    let existingUploads = [];
    let remainingUploads = new Map<DokumentTyp, number>();
    this.dokumentTypen?.forEach( typ => {
      let existing = existingTypen.filter( it => it === typ.id ).length;
      if (existing > 0) {
        existingUploads.push(typ);
      }
      let remaining = typ.maxUploadCount - existing;
      if (remaining > 0) {
        remainingUploads.set(typ, remaining);
      }
    });
    this.existingUploads = existingUploads;
    this.remainingUploads = remainingUploads;
    this.availableUploads = Array.from(this.remainingUploads.keys());
    this.preventRedirect.emit(this.unsavedDokumente.length > 0);
  }

  selectDokument(typ: DokumentTyp): void {
    this.selectedUpload = typ;
    this.fileInput.nativeElement.multiple = this.remainingUploads.get(typ) > 1 ? 'multiple' : null;
    this.fileInput.nativeElement.click();
  }

  uploadDokument(event): void {
    const files: FileList = event.target.files;
    if (files?.length > this.remainingUploads.get(this.selectedUpload)) {
      this.errorMessage = this.translateService.instant('dokument.maxCount', {
        max   : this.remainingUploads.get(this.selectedUpload),
        count : files.length
      });
    } else if (files?.length) {
      this.errorMessage = null;
      for (let i = 0; i < files?.length; i++) {
        let file = files[i];
        this.backendService.uploadDokument(this.selectedUpload, file)
          .subscribe({
            next: it => {
              this.unsavedDokumente.push(it);
              this.refreshRemainingUploads();
            },
            error: error => this.errorMessage = error
          });
      }
      this.fileInput.nativeElement.value = null;
    }
  }

  saveDokumente(): void {
    if (this.unsavedDokumente.length) {
      let dokumentIds = this.unsavedDokumente.map( it => it.id );
      this.backendService.linkDokumente(this.meldungId, dokumentIds)
        .subscribe({
          next: dokumente => {
            this.unsavedDokumente = [];
            this.dokumente = dokumente;
            this.refreshRemainingUploads();
          },
          error: console.error
        });
    }
  }

  downloadDokument(dokument: Dokument): void {
    this.backendService.downloadDokument(dokument)
      .subscribe({
        next: response => {
          downloadFile(response);
          if (dokument.highlight) this.refresh();
        },
        error: console.error
      });
  }

  markUnread(dokument: Dokument): void {
    this.backendService.markUnread(dokument)
      .subscribe( () => this.refresh() );
  }

  canDelete(dokument: Dokument): boolean {
    return dokument.typ.canDelete && (dokument.temporary || this.authorizationService.isBlv())
  }

  deleteDokument(dokument: Dokument): void {
    Swal.fire({
      text: this.translateService.instant('dokument.delete.message', {name: dokument.name}),
      icon: 'warning',
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('dokument.delete.yes'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.backendService.deleteDokument(dokument)
          .subscribe( () => {
            let index = this.unsavedDokumente.indexOf(dokument);
            if (index < 0) {
              this.refresh();
            } else {
              this.unsavedDokumente.splice(index, 1);
              this.refreshRemainingUploads();
            }
          });
      }
      this.onDelete.emit();
    });
  }

  get showAlertImmunologisch(): boolean {
    return !!this.availableUploads.find( it => it.id === DokumentTypId.LABORANALYSEN_AUTOVAKZINE );
  }

  get showAlertLieferEngpass(): boolean {
    return !!this.availableUploads.find( it => it.id === DokumentTypId.BESTAETIGUNG_LIEFERENGPASS );
  }

  get iconAlertImmunologisch(): string {
    return this.existingUploads.find( it => it.id === DokumentTypId.LABORANALYSEN_AUTOVAKZINE )
      ? ''
      : 'icon icon--before icon--exclam red';
  }

  get iconAlertLieferEngpass(): string {
    return this.existingUploads.find( it => it.id === DokumentTypId.BESTAETIGUNG_LIEFERENGPASS )
      ? ''
      : 'icon icon--before icon--exclam red';
  }
}
