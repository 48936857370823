<div class="container admin">
  <!-- Falls die API-Abfrage einen Fehler liefert, wird der Fehlertext angezeigt -->
  <ng-template [ngIf]="setListError" [ngIfElse]="noError">
    <div class="row">
      <p style="font-weight: bold; color: red;">{{ 'admin.error' | translate }}</p>
    </div>
  </ng-template>
  <ng-template #noError>
    <div class="row admin">
      <p class="admin-warning"><b>{{ 'admin.warning' | translate }}</b></p>
    </div>
    <app-admin-stammdaten-land-new (reloadEntries)="reloadEntries()"></app-admin-stammdaten-land-new>
    <div class="row admin">
      <div class="col-md-2 pl-0 mb-0"><p class="admin"><b>{{ 'admin.land.column.de' | translate }}</b></p></div>
      <div class="col-md-2 pl-0 mb-0"><p class="admin"><b>{{ 'admin.land.column.fr' | translate }}</b></p></div>
      <div class="col-md-2 pl-0 mb-0"><p class="admin"><b>{{ 'admin.land.column.it' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.land.column.sort' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.land.column.zulassungsLand' | translate }}</b></p></div>
      <div class="col-md-1 pl-0 mb-0"><p class="admin"><b>{{ 'admin.land.column.importLand' | translate }}</b></p></div>
      <div class="col-md-0.5 pl-0 mb-0"><p class="admin">
        <b [matTooltip]="'admin.land.column.nutztier.tooltip' | translate" matTooltipPosition="above">
          {{ 'admin.land.column.nutztier' | translate }}
        </b>
      </p></div>
      <div class="col-md-0.5 pl-0 mb-0"><p class="admin">
        <b [matTooltip]="'admin.land.column.heimtier.tooltip' | translate" matTooltipPosition="above">
          {{ 'admin.land.column.heimtier' | translate }}
        </b>
      </p></div>
      <div class="col-md-2 pl-0 mb-0"></div>
    </div>
    <div class="row admin" *ngFor="let set of laender; let i = index"
         app-admin-stammdaten-land-entry
         [set]="set"
         (reloadEntries)="reloadEntries()"
         (deleted)="onLandDeleted(i)">
    </div>
  </ng-template>
</div>
