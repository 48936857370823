import { DokumentTyp, DokumentTypId } from "@app/shared/classes/dokument-typ";

export class DokumentTypSelector {
  DETAILHANDELS_BEWILLIGUNG_KANTON: DokumentTyp;
  BESTAETIGUNG_LIEFERENGPASS: DokumentTyp;
  LABORANALYSEN_AUTOVAKZINE: DokumentTyp;
  BEWILLIGUNG_BLV: DokumentTyp;

  typen: DokumentTyp[];
  typenL: DokumentTyp[];
  typenI: DokumentTyp[];
  typenLI: DokumentTyp[];


  constructor(dokumentTypen: DokumentTyp[]) {
    this.DETAILHANDELS_BEWILLIGUNG_KANTON         = dokumentTypen.find( it => it.id === DokumentTypId.DETAILHANDELS_BEWILLIGUNG_KANTON );
    this.BESTAETIGUNG_LIEFERENGPASS               = dokumentTypen.find( it => it.id === DokumentTypId.BESTAETIGUNG_LIEFERENGPASS );
    this.LABORANALYSEN_AUTOVAKZINE                = dokumentTypen.find( it => it.id === DokumentTypId.LABORANALYSEN_AUTOVAKZINE );
    this.BEWILLIGUNG_BLV                          = dokumentTypen.find( it => it.id === DokumentTypId.BEWILLIGUNG_BLV );

    this.typen = [this.DETAILHANDELS_BEWILLIGUNG_KANTON].filter( it => it );
    this.typenL = [...this.typen, this.BESTAETIGUNG_LIEFERENGPASS].filter( it => it );
    this.typenI = [...this.typen, this.LABORANALYSEN_AUTOVAKZINE].filter( it => it );
    this.typenLI = [...this.typenL, this.LABORANALYSEN_AUTOVAKZINE].filter( it => it );
  }

  getTypen(lieferEngpass: boolean, immunologisch: boolean): DokumentTyp[] {
    if (lieferEngpass) {
      return immunologisch ? this.typenLI : this.typenL;
    }
    return immunologisch ? this.typenI : this.typen;
  }
}
