import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '@app/admin/admin.component';
import { ForbiddenComponent } from '@app/layouts/errors/forbidden/forbidden.component';
import { NotFoundComponent } from '@app/layouts/errors/not-found/not-found.component';
import { UnauthorizedComponent } from '@app/layouts/errors/unauthorized/unauthorized.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import {MeldungNewComponent} from "@app/meldung-new/meldung-new.component";
import {MeldungComponent} from "@app/meldung/meldung.component";
import {MeldungListComponent} from "@app/meldung-list/meldung-list.component";
import { AuthorizationService } from "@app/core/auth/authorization.service";
import { AuthenticationService } from "@app/core/auth/authentication.service";

const routes: Routes = [
  {
    path: '', component: MainLayoutComponent,
    children: [
      { path: '', redirectTo: '/meldung', pathMatch: 'full' },
      { path: 'extern', redirectTo: '/meldung' },

      // Error routes
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: 'not-found', component: NotFoundComponent },
      {
        path: '',
        canActivateChild: [
          (route: ActivatedRouteSnapshot) => inject(AuthenticationService).canActivate(route),
          (route: ActivatedRouteSnapshot) => inject(AuthorizationService).canActivate(route)
        ],
        children: [
          { path: 'meldung/new', component: MeldungNewComponent, data: { roles: ['ISABV'] }  },
          {
            path: 'meldung/:id',
            component: MeldungComponent,
            canDeactivate: [(comp: MeldungComponent) => comp.canDeactivate()]
          },
          { path: 'meldung', component: MeldungListComponent },
          { path: 'admin', component: AdminComponent, data: { roles: ['BLV-tamimport.ALLOW'] } },
          { path: 'admin/:category', component: AdminComponent, data: { roles: ['BLV-tamimport.ALLOW'] } }
        ]
      },

      // Wildcard route to redirect to not found page
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
