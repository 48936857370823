<div class="row">
  <div class="col-sm-12">
    <table class="table table-sort">
      <thead>
        <tr>
          <th class="{{ classSpaltenBreite }} {{ getSorting('erfDate') }}" (click)="toggleSorting('erfDate')">
            {{ "meldung.feld.erfDate" | translate }}
          </th>
          <th class="{{ classSpaltenBreite }} {{ getSorting('nummer') }}" (click)="toggleSorting('nummer')">
            {{ "meldung.feld.nummer.mixed" | translate }}
          </th>
          <th class="{{ classSpaltenBreite }} {{ getSorting('uid') }}" (click)="toggleSorting('uid')" *appIsBlv>
            {{ "meldung.feld.uid" | translate }}
          </th>
          <th class="{{ classSpaltenBreite }} {{ getSorting('praxis') }}" (click)="toggleSorting('praxis')" *appIsBlv>
            {{ "meldung.feld.praxis" | translate }}
          </th>
          <th class="{{ classSpaltenBreite }} {{ getSorting('zulassungsLand') }}" (click)="toggleSorting('zulassungsLand')">
            {{ "meldung.feld.praeparat.zulassungsLand" | translate }}
          </th>
          <th class="{{ classSpaltenBreite }} {{ getSorting('name') }}" (click)="toggleSorting('name')">
            {{ "meldung.feld.praeparat.name" | translate }}
          </th>
          <th style="width: 1%" [class]="getSorting('notiz')" (click)="toggleSorting('notiz')" *appIsBlv></th>
          <td style="width: 1%"></td>
          <td style="width: 1%"></td>
          <td style="width: 1%"></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let meldung of meldungen" [ngClass]="statusFarbe[meldung.status.id]">
          <td>{{ meldung.erfDate | date:'dd.MM.yyyy HH:mm' }}</td>
          <td>
            <a routerLink="{{ meldung.id }}">
              {{ meldung.nummer }}
            </a>
          </td>
          <td *appIsBlv>{{ meldung.uid }}</td>
          <td *appIsBlv>{{ meldung.praxis }}</td>
          <td>{{ meldung.praeparat?.zulassungsLand?.bezeichnung }}</td>
          <td>{{ meldung.praeparat?.name }}</td>
          <td *appIsBlv>
            <span [class]="generateNotizIcon(meldung)" [matTooltip]="meldung.notiz?.notizBlv" matTooltipClass="line-break" matTooltipPosition="right"></span>
          </td>
          <td>
            <span class="meldung-list-icon {{ meldung.requiredDokumente.icon }}" [matTooltip]="generateDokumentTooltip(meldung)" matTooltipClass="line-break" matTooltipPosition="right"></span>
          </td>
          <td>
            <button (click)="$event.stopPropagation(); oeffneDokumente(meldung.id)" style="margin-left:15px;"
                    class="btn btn-default glyphicon glyphicon-paperclip ml-0" [class]="meldung.highlight"
                    role="button" [matTooltip]="'global.tooltip.dokument.anzeigen' | translate"
                    matTooltipPosition="right"></button>
          </td>
          <td>
            <button (click)="$event.stopPropagation(); downloadPdf(meldung.id)" style="margin-left:15px;"
                    class="btn btn-default glyphicon glyphicon-print ml-0"
                    role="button" *ngIf="showPrinter(meldung, false)"
                    [matTooltip]="'global.tooltip.pdf' | translate"
                    matTooltipPosition="right"></button>
            <span class="dropdown pull-right" appDropdown *ngIf="showPrinter(meldung, true)">
              <button class="btn btn-default glyphicon glyphicon-print ml-0 dropdown-toggle"
                      role="button" type="button" data-toggle="dropdown"
                      [matTooltip]="'global.tooltip.pdf' | translate"
                      matTooltipPosition="right"></button>
              <ul class="dropdown-menu">
                <li>
                  <a class="cursor-pointer" (click)="downloadPdf(meldung.id)">{{ "templateType.bewilligungs_antrag" | translate }}</a>
                </li>
                <li>
                  <a class="cursor-pointer" (click)="downloadBewilligung(meldung.id)">{{ "templateType.bewilligung" | translate }}</a>
                </li>
              </ul>
            </span>
          </td>
          <td>
            <button (click)="$event.stopPropagation(); kopieren(meldung.id)" style="margin-left:15px;"
                    class="btn btn-default glyphicon glyphicon-duplicate ml-0"
                    role="button" *appIsTierarzt
                    [matTooltip]="'global.tooltip.copy' | translate"
                    matTooltipPosition="right"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-sm-offset-5 col-sm-2">
    <a (click)="nachladen()" class="btn btn-default" role="button" *ngIf="nachladenAnzeigen">
      {{ "meldung.list.nachladen" | translate }}
    </a>
  </div>
</div>
<div class="hidden">
  <div #dokumentComponent>
    <dokument [id]="dokumenteForMeldung" (onDelete)="oeffneDokumente(dokumenteForMeldung)" (preventRedirect)="preventDokumentClose = $event"></dokument>
  </div>
</div>
