import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {Meldung} from '@app/shared/classes/meldung';
import {BackendService} from "@app/shared/services/backend.service";
import { MeldungFilter } from "@app/shared/classes/meldung-filter";
import { Router } from "@angular/router";
import { AuthorizationService } from "@app/core/auth/authorization.service";
import { downloadFile } from "@app/shared/download-utils";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { StatusId } from "@app/shared/classes/status";
import { SettingsService } from "@app/core/config/settings.service";

@Component({
  selector: 'app-meldung-list-table',
  templateUrl: './meldung-list-table.component.html'
})
export class MeldungListTableComponent implements OnInit {
  _filter: MeldungFilter;
  meldungen: Meldung[];
  nachladenAnzeigen: boolean = false;
  statusFarbe = {20: '', 50: 'text-info', 90: 'text-muted'};
  @Output() totalMeldungenEvent = new EventEmitter<number>();
  dokumenteForMeldung: number;
  preventDokumentClose: boolean;
  @ViewChild('dokumentComponent') dokumentComponent: ElementRef;

  // Prevent racing condition
  reloadVersion = 0;

  constructor(private backendService: BackendService,
              private router: Router,
              private settingsService: SettingsService,
              private translateService: TranslateService,
              private authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload(): void {
    this.filter.page = 0;
    let version = ++this.reloadVersion;
    this.backendService.listMeldungen(this.filter)
      .subscribe(meldungList => {
        // Sicherstellen, dass nicht in der Zwischenzeit ein neuer Reload getriggert wurde
        if (this.reloadVersion === version) {
          this.meldungen = meldungList.results;
          this.nachladenAnzeigen = meldungList.hasMore;
          this.totalMeldungenEvent.emit(meldungList.totalResults);
        }
      });
  }

  nachladen(): void {
    this.filter.page++;
    this.backendService.listMeldungen(this.filter)
      .subscribe(meldungList => {
        this.meldungen.push(...meldungList.results);
        this.nachladenAnzeigen = meldungList.hasMore;
        this.totalMeldungenEvent.emit(meldungList.totalResults);
      });
  }

  oeffneDokumente(id: number): void {
    this.dokumenteForMeldung = id;
    Swal.fire({
      html: this.dokumentComponent.nativeElement,
      width: 'auto',
      didClose: () => this.closeDokumente(id)
    });
  }

  closeDokumente(id: number): void {
    if (this.preventDokumentClose) {
      Swal.fire({
        text: this.translateService.instant('dokument.unsaved.message'),
        icon: 'warning',
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('global.yes'),
        cancelButtonText: this.translateService.instant('global.no')
      }).then( (result) => {
        if (result.isConfirmed) {
          this.aktualisiere(id);
          this.dokumenteForMeldung = 0;
        } else {
          this.oeffneDokumente(id);
        }
      });
    } else {
      this.aktualisiere(id);
      this.dokumenteForMeldung = 0;
    }
  }

  aktualisiere(id: number): void {
    this.backendService.getMeldung(id)
      .subscribe(meldung => {
        let index = this.meldungen.findIndex( m => m.id === id );
        this.meldungen[index] = meldung;
      });
  }

  downloadPdf(id: number): void {
    this.backendService.getMeldungPdf(id)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  downloadBewilligung(id: number): void {
    this.backendService.downloadBewilligung(id)
      .subscribe({
        next: downloadFile,
        error: console.error
      });
  }

  kopieren(id: number): void {
    this.router.navigate(['meldung', 'new'], {queryParams: {copy: id}})
  }

  toggleSorting(sortId: string): void {
    if (this.filter.sortColumn != sortId) {
      this.filter.sortColumn = sortId;
      this.filter.sortOrder = 'Asc';
    } else if(this.filter.sortOrder == 'Asc') {
      this.filter.sortOrder = 'Desc';
    } else {
      this.filter.sortOrder = 'Asc'
    }
    this.reload();
  }

  getSorting(sortId: string): string {
    return this.filter.sortColumn == sortId ? `tablesorter-header${this.filter.sortOrder}` : '';
  }

  generateNotizIcon(meldung: Meldung): string {
    if (meldung.notiz?.offeneAnfrage) {
      return 'meldung-list-icon glyphicon glyphicon-envelope'
    }
    return meldung.notiz?.notizBlv ? 'meldung-list-icon glyphicon glyphicon-comment' : null
  }

  generateDokumentTooltip(meldung: Meldung): string {
    if (meldung.requiredDokumente.generatedTooltip === undefined) {
      meldung.requiredDokumente.generatedTooltip = meldung.requiredDokumente.tooltip.map( (it) => {
        return it.icon + ' ' + this.translateService.instant(it.messageProperty);
      }).join('\n');
    }
    return meldung.requiredDokumente.generatedTooltip;
  }

  showPrinter(meldung: Meldung, extended: boolean) {
    return meldung.status.id !== StatusId.STORNIERT
      && meldung.requiredDokumente.bewilligungVorhanden === extended
  }

  get filter(): MeldungFilter {
    return this._filter;
  }

  get classSpaltenBreite(): string {
    return this.authorizationService.isBlv() ? 'col-sm-2' : 'col-sm-3'
  }

  @Input()
  set filter(filter: MeldungFilter) {
    this._filter = filter;
    if (this.authorizationService.isBlv()) {
      this._filter.limit = this.settingsService.getInteger('filter.limit.blv', 25);
    } else {
      this._filter.limit = this.settingsService.getInteger('filter.limit.tierarzt', 0);
    }
    this.reload();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(event: BeforeUnloadEvent): void {
    if (this.preventDokumentClose) {
      event.preventDefault();
      event.returnValue = this.translateService.instant('dokument.unsaved.message');
    }
  }
}
