<div class="row">
  <div class="col-sm-12 mb-0">
    <div class="col-sm-12 mb-0">
      <p class="tam-alert mb-def" *ngIf="showAlertImmunologisch">
        <i [class]="iconAlertImmunologisch"></i>
        <span [innerHTML]="'meldung.feld.praeparat.alert.immunologisch' | translate | marked:true"></span>
      </p>
      <p class="tam-alert mb-def" *ngIf="showAlertLieferEngpass">
        <i [class]="iconAlertLieferEngpass"></i>
        <span [innerHTML]="'meldung.feld.praeparat.alert.lieferEngpass' | translate | marked:true | injectUrl:'url.lieferEngpass'"></span>
      </p>
    </div>
  </div>
</div>
<table *ngIf="selectedDokumente.length" class="dokument-table mb-15">
  <tr *ngFor="let dokument of selectedDokumente">
    <td>{{ dokument.typ.messageProperty | translate }}</td>
    <td>
      <a class="cursor-pointer" (click)="downloadDokument(dokument)">
        {{ dokument.name }}
      </a>
    </td>
    <td>
      <span class="glyphicon glyphicon-trash cursor-pointer"
         [matTooltip]="'global.tooltip.dokument.loeschen' | translate" matTooltipPosition="right"
         (click)="deleteDokument(dokument)" tabindex="0"></span>
    </td>
  </tr>
</table>
<div class="row" [ngClass]="{'has-error': errorMessage}" *ngIf="availableUploads.length" tabindex="-1" #focusAfterUpload>
  <div class="col-sm-12 mb-0">
    <div class="col-sm12 mb-0">
      <input class="hidden" type="file" accept="image/*,.pdf" (change)="uploadDokument($event)" #fileInput>
      <button class="btn btn-default ml-15" *ngFor="let dokumentTyp of availableUploads" (click)="$event.preventDefault(); selectDokument(dokumentTyp)">
        {{ dokumentTyp.messageProperty | translate }}
      </button>
    </div>
    <div class="col-sm-12 mb-0 help-block" role="alert" *ngFor="let msg of errorMessage">
      {{ msg }}
    </div>
  </div>
</div>
