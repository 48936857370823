import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Configuration } from '@app/core/config/configuration';
import { ConfigurationService } from '@app/core/config/configuration.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationService implements OnDestroy {

  authorities$ = new BehaviorSubject<string[]>([]);
  private authorities: string[] = [];

  private destroyed$ = new Subject();
  private config: Configuration;

  constructor(private authenticationService: AuthenticationService,
              private configurationService: ConfigurationService,
              private router: Router) {
    this.config = configurationService.getConfig();
    this.authenticationService.authenticated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(authenticated => {
        let roles = [];
        if (authenticated) {
          const user = this.authenticationService.getUser();
          roles = [].concat(user.roles);
          this.updateAuthorities(roles);
        } else {
          this.updateAuthorities([]);
          this.router.navigateByUrl('/unauthorized');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private updateAuthorities(authorities: string[]) {
    this.authorities = authorities;
    this.authorities$.next(this.getAuthorities());
  }

  getAuthorities(): string[] {
    return this.authorities.slice();
  }

  hasAuthority(authority: string): boolean {
    return this.authorities.includes(authority);
  }

  hasAny(authorities: string[]): boolean {
    return authorities.some(authority => this.hasAuthority(authority));
  }

  isTierarzt(): boolean {
    return this.hasAuthority('ISABV');
  }

  isBlv(): boolean {
    return this.hasAuthority('BLV-tamimport.ALLOW')
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.authenticationService.checkLoginState()
      .then(() => {
        if (route.data.roles) {
          return this.hasAny(route.data.roles) || this.authenticationService.generateUrlTree(route, '/forbidden');
        }
        return this.getAuthorities().length > 0 || this.authenticationService.generateUrlTree(route, '/forbidden');
      });
  }
}
