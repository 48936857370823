<header>
  <div class="clearfix">
    <nav class="navbar treecrumb pull-left" role="navigation">
      <h2 class="sr-only">{{ 'global.blv' | translate }}</h2>
      <ul class="nav navbar-nav">
        <li class="dropdown">
          <a id="federalAdministration-dropdown"
             href="http://www.admin.ch/gov/{{currentLanguage}}/start.html"
             class="dropdown-toggle" target="_blank">
            <span class="icon icon--right"></span>{{ 'global.navigation.bundesrat' | translate }}</a>
        </li>
        <li class="dropdown">
          <a id="departement-dropdown" href="http://www.edi.admin.ch/edi/{{currentLanguage}}/home.html"
             class="dropdown-toggle" target="_blank">
            <span class="icon icon--right"></span>{{ 'global.navigation.edi' | translate }}</a>
        </li>
        <li class="dropdown">
          <a id="federalOffice-dropdown" href="http://www.blv.admin.ch/blv/{{currentLanguage}}/home.html"
             class="dropdown-toggle" target="_blank">
            <span class="icon icon--right"></span>{{ 'global.navigation.blv' | translate }}</a>
        </li>
      </ul>
    </nav>

    <section class="nav-services clearfix">
      <h2 class="sr-only">Sprache</h2>
      <nav class="nav-lang">
        <ul>
          <li *ngFor="let langKurz of availableLanguages">
            <a (click)="changeLanguage(langKurz)"
               [ngClass]="currentLanguage === langKurz.toLowerCase() ? 'active' : ''">
              {{ langKurz }}
            </a>
          </li>
        </ul>
      </nav>
      <nav class="nav-service" role="navigation">
        <ul>
          <li>
            <a routerLink="/">
              {{ 'global.navigation.startPage' | translate }}
            </a>
          </li>
          <li id="admin-link" *appIsBlv>
            <a routerLink="/admin" [attr.aria-label]="'global.navigation.adminPage' | translate"
               title="{{ 'global.navigation.adminPage' | translate }}"><span class="icon icon--settings" aria-hidden="true"></span></a>
          </li>
          <li *appIsBlv>
            <!-- TODO: LinkGenerator for EIAM User Account? -->
            <a role="menuitem" (click)="userAccount()">{{ 'global.navigation.userAccount' | translate }}</a>
          </li>
          <li>
            <a role="menuitem" (click)="logout()">{{ 'global.navigation.logout' | translate }}</a>
          </li>
        </ul>
      </nav>
    </section>
  </div>

  <div class="clearfix">
    <div class="flex-container">
      <a routerLink="/" class="brand hidden-xs">
        <img src="../../assets/cdbund/img/logo-CH.svg" alt="bund-logo" [attr.aria-label]="'global.home' | translate"/>
        <h1>
          {{ 'global.blv' | translate }}
          <br>
          {{ 'global.label' | translate }}
        </h1>
      </a>
    </div>
  </div>
</header>
<div class="header-separator"></div>
