import { Component, OnInit } from '@angular/core';
import { LandAdmin } from '@app/shared/classes/land';
import { BackendService } from '@app/shared/services/backend.service';

@Component({
  selector: 'app-admin-stammdaten-land',
  templateUrl: './admin-stammdaten-land.component.html'
})
export class AdminStammdatenLandComponent implements OnInit {
  laender: LandAdmin[] = [];
  setListError = false;

  constructor(private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.backendService.listLaender()
      .subscribe({
        next: result => {
          this.laender = result;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.error(error);
        }
      });
  }

  onLandDeleted(index: number) {
    this.laender.splice(index, 1);
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
