import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DeaktivierungsGrundAdmin } from '@app/shared/classes/deaktivierungs-grund';
import { BackendService } from '@app/shared/services/backend.service';
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-admin-stammdaten-deaktivierungsgrund-new',
  templateUrl: './admin-stammdaten-deaktivierungsgrund-new.component.html'
})
export class AdminStammdatenDeaktivierungsgrundNewComponent implements OnInit {
  @Output() deleted = new EventEmitter();

  newGrundForm: UntypedFormGroup;

  grund: DeaktivierungsGrundAdmin = new DeaktivierungsGrundAdmin();

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.newGrundForm = this.formBuilder.group({
      sortierung_new: ['', [Validators.pattern('^[0-9]*$'), Validators.required]],
      bezeichnungDe_new: ['', Validators.required],
      bezeichnungFr_new: ['', Validators.required],
      bezeichnungIt_new: ['', Validators.required],
      textDe_new: ['', Validators.required],
      textFr_new: ['', Validators.required],
      textIt_new: ['', Validators.required]
    });
  }

  onSubmit() {
    const formValues = this.newGrundForm.value;
    this.grund.sortierung = formValues.sortierung_new;
    this.grund.bezeichnungDe = formValues.bezeichnungDe_new;
    this.grund.bezeichnungFr = formValues.bezeichnungFr_new;
    this.grund.bezeichnungIt = formValues.bezeichnungIt_new;
    this.grund.textDe = formValues.textDe_new;
    this.grund.textFr = formValues.textFr_new;
    this.grund.textIt = formValues.textIt_new;
    this.backendService.createDeaktivierungsGrund(this.grund)
      .subscribe({
        next: () => {
          this.initForm();
          this.reloadEntries.emit();
        },
        error: error => {
          this.showAlert();
          console.error(error);
        }
      });
  }

  onReset() {
    this.ngOnInit();
  }

  showAlert() {
    Swal.fire({
      text: this.translateService.instant('admin.deaktivierungsgrund.save.error'),
      icon: 'error',
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
