import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BackendService } from '@app/shared/services/backend.service';
import { TemplateType } from '@app/shared/classes/template-type';
import { downloadFile } from '@app/shared/download-utils';
import { type TemplateListChangedMap } from '@app/shared/classes/template-list';
import { type Sprache } from '@app/shared/types';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-admin-stammdaten-template',
  templateUrl: './admin-stammdaten-template.component.html'
})
export class AdminStammdatenTemplateComponent implements OnInit {
  sprachen: Sprache[] = ['DE', 'FR', 'IT'];
  types: TemplateType[] = [];
  setListError = false;
  uploadSuccess: string = null;
  uploadError: string = null;
  downloadError: string = null;
  @ViewChild('fileInput') fileInput: ElementRef;
  changed: TemplateListChangedMap;

  constructor(private backendService: BackendService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.backendService.listTemplateTypes()
      .subscribe({
        next: result => {
          this.types = result.types;
          this.changed = result.changed;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.error(error);
        }
      });
  }

  upload(type: TemplateType, sprache: Sprache, event) {
    const files: FileList = event.target.files;
    this.uploadSuccess = null;
    this.uploadError = null;
    this.downloadError = null;
    this.backendService.uploadTemplate(type, sprache, files[0])
      .subscribe({
        next: result => {
          console.log(result);
          this.uploadSuccess = this.translateService.instant(type.messageProperty) + ' ' + sprache;
          this.ngOnInit();
        },
        error: error => {
          console.error(error);
          this.uploadError = this.translateService.instant(type.messageProperty) + ' ' + sprache;
        }
      });
    this.fileInput.nativeElement.value = null;
  }

  download(type: TemplateType, sprache: Sprache) {
    this.uploadSuccess = null;
    this.uploadError = null;
    this.downloadError = null;
    this.backendService.downloadTemplate(type, sprache)
      .subscribe({
        next: downloadFile,
        error: error => {
          console.error(error);
          this.downloadError = this.translateService.instant(type.messageProperty) + ' ' + sprache;
        }
      });
  }

  downloadTooltip(type: TemplateType, sprache: Sprache): string {
    let tooltip = this.translateService.instant('admin.template.download.tooltip');
    let changed = this.changed[sprache][type.id];
    if (changed) {
      tooltip += '\n(' + formatDate(changed, 'dd.MM.yyyy HH:mm', 'de-CH') + ')'
    }
    return tooltip;
  }

  downloadDummy() {
    this.uploadSuccess = null;
    this.uploadError = null;
    this.downloadError = null;
    this.backendService.downloadDummyTemplate()
      .subscribe({
        next: downloadFile,
        error: error => {
          console.error(error);
          this.downloadError = this.translateService.instant('admin.template.dummy.label');
        }
      });
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
