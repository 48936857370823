<div class="container-fluid">
  <!-- Actionbar -->
  <div class="row" *appIsTierarzt>
    <div class="col-sm-12 mb-0">
      <a routerLink="new" style="margin-left:15px;"
         class="btn btn-default ml-0" role="button">
        {{ "meldung.new.link" | translate }}
      </a>
    </div>
  </div>
  <!-- Title -->
  <h1>
    {{ "meldung.list.title" | translate }}
  </h1>
  <!-- Filter -->
  <form [formGroup]="filterForm">
    <div class="row">
      <div class="col-sm-9 mb-0">
        <div class="row form-group">
          <div [class]="classFreitext">
            <input [placeholder]="'meldung.list.placeholder.freitext' | translate" class="form-control" formControlName="freitext">
          </div>
          <div class="col-sm-2.5 mb-0" *appIsBlv>
            <select class="form-control width100" formControlName="blvStatus">
              <option value="0">{{ 'meldung.list.placeholder.blvStatus' | translate }}</option>
              <option value="20">{{ 'blvStatus.unvollstaendig' | translate }}</option>
              <option value="80">{{ 'blvStatus.vollstaendig' | translate }}</option>
            </select>
          </div>
          <div class="col-sm-2 mb-0" *appIsBlv>
            <button (click)="erweiterterFilterAnzeigen()" class="width100">
              {{ 'meldung.list.erweitert' | translate}}
              <i class="red icon icon--after icon--exclam" *ngIf="erweiterterFilterAktiv()"></i>
            </button>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-2 mb-0">
            <div class="clear-input-button" (click)="clearValue('datumVon')"></div>
            <input [placeholder]="'meldung.list.placeholder.datumVon' | translate" class="form-control" formControlName="datumVon" [dpDayPicker]="datumVonBis" theme="dp-material">
          </div>
          <div class="col-sm-2 mb-0">
            <div class="clear-input-button" (click)="clearValue('datumBis')"></div>
            <input [placeholder]="'meldung.list.placeholder.datumBis' | translate" class="form-control" formControlName="datumBis" [dpDayPicker]="datumVonBis" theme="dp-material">
          </div>
          <div [class]="classLandStatus">
            <select class="form-control width100" formControlName="land">
              <option value="0">{{ 'meldung.list.placeholder.land' | translate }}</option>
              <option *ngFor="let land of laender" [value]="land.id">
                {{land.bezeichnung}}
              </option>
            </select>
          </div>
          <div [class]="classLandStatus">
            <select class="form-control width100" formControlName="status">
              <option value="0">{{ 'meldung.list.placeholder.status' | translate }}</option>
              <option *ngFor="let status of statusList" [value]="status.id">
                {{ status.messageProperty | translate }}
              </option>
            </select>
          </div>
          <div class="col-sm-3 mb-0" *appIsBlv>
            <select class="form-control width100" formControlName="offeneAnfrage">
              <option value="0">{{ 'meldung.list.placeholder.offeneAnfrage' | translate }}</option>
              <option value="1">{{ 'global.yes' | translate }}</option>
              <option value="2">{{ 'global.no' | translate }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-3 mb-0">
        <div class="row form-group">
          <div class="col-sm-12 mb-0">
            <button class="btn pull-right ml-15" (click)="resetFilter()">{{ 'meldung.list.reset' | translate }}</button>
            <span class="dropdown pull-right" appDropdown>
              <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                {{ "meldung.list.export" | translate }}
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="cursor-pointer" (click)="downloadCsv()">{{ "meldung.generate.csv" | translate }}</a>
                </li>
                <li>
                  <a class="cursor-pointer" (click)="downloadXls()">{{ "meldung.generate.xls" | translate }}</a>
                </li>
                <li>
                  <a class="cursor-pointer" (click)="downloadZoll()" *appIsBlv>{{ "meldung.generate.zoll" | translate }}</a>
                </li>
              </ul>
            </span>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12 mb-0">
            <span class="pull-right">
              <strong>{{ "meldung.list.anzahl" | translate }} {{ totalMeldungen }}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Tabelle -->
  <app-meldung-list-table [filter]="filter" (totalMeldungenEvent)="totalMeldungen = $event"></app-meldung-list-table>
</div>
<div class="hidden">
  <div #filterComponent>
    <meldung-list-filter [(bewilligung)]="bewilligung"></meldung-list-filter>
  </div>
</div>
