import { Component, OnInit } from '@angular/core';
import { DeaktivierungsGrundAdmin } from '@app/shared/classes/deaktivierungs-grund';
import { BackendService } from '@app/shared/services/backend.service';

@Component({
  selector: 'app-admin-stammdaten-deaktivierungsgrund',
  templateUrl: './admin-stammdaten-deaktivierungsgrund.component.html'
})
export class AdminStammdatenDeaktivierungsgrundComponent implements OnInit {
  gruende: DeaktivierungsGrundAdmin[] = [];
  setListError = false;

  constructor(private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.backendService.listDeaktivierungsGruendeAdmin()
      .subscribe({
        next: result => {
          this.gruende = result;
          this.setListError = false;
        },
        error: error => {
          this.setListError = true;
          console.error(error);
        }
      });
  }

  onGrundDeleted(index: number) {
    this.gruende.splice(index, 1);
  }

  reloadEntries(): void {
    this.ngOnInit();
  }
}
