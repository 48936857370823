<header class="page-header front-header">
  <div class="container admin">
    <div class="row">
      <div class="col-sm-6">
        <h1>{{ 'global.navigation.adminPage' | translate }}</h1>
      </div>
      <div class="col-sm-6 admin-header">
        <p class="col-sm-12 font-weight-bold">{{ 'admin.version.title' | translate }}</p>
        <p class="col-sm-5">{{ 'admin.version.frontend' | translate }}</p>
        <p class="col-sm-7">{{ getFrontendVersion }}</p>
        <p class="col-sm-5">{{ 'admin.version.backend' | translate }}</p>
        <p class="col-sm-7">{{ getBackendVersion }}</p>
      </div>
    </div>
  </div>
</header>

<div class="admin-content" role="main">
  <section id="explore" class="container">
    <app-tabs (tabChanged)="onCategoryChange($event)" [selectedId]="currentCategory">
      <app-tab tabId="settings" [tabTitle]="'admin.settings.tab' | translate">
        <div class="tab-content tab-border">
          <app-admin-settings></app-admin-settings>
        </div>
      </app-tab>
      <app-tab tabId="laender" [tabTitle]="'admin.land.tab' | translate">
        <div class="tab-content tab-border">
          <app-admin-stammdaten-land></app-admin-stammdaten-land>
        </div>
      </app-tab>
      <app-tab tabId="importgruende" [tabTitle]="'admin.importgrund.tab' | translate">
        <div class="tab-content tab-border">
          <app-admin-stammdaten-importgrund></app-admin-stammdaten-importgrund>
        </div>
      </app-tab>
      <app-tab tabId="deaktivierungsgruende" [tabTitle]="'admin.deaktivierungsgrund.tab' | translate">
        <div class="tab-content tab-border">
          <app-admin-stammdaten-deaktivierungsgrund></app-admin-stammdaten-deaktivierungsgrund>
        </div>
      </app-tab>
      <app-tab tabId="template" [tabTitle]="'admin.template.tab' | translate">
        <div class="tab-content tab-border">
          <app-admin-stammdaten-template></app-admin-stammdaten-template>
        </div>
      </app-tab>
      <app-tab tabId="log" [tabTitle]="'admin.log.tab' | translate">
        <div class="tab-content tab-border">
          <div class="row admin">
            <div class="col-sm-6 log-period"><p class="admin log-period">{{ 'admin.log.title' | translate }}</p>
              <select class="log-period" id="select-log-period" name="sort" [(ngModel)]="selectedLogPeriod">
                <option selected value="1">{{ 'admin.log.period.tag' | translate }}</option>
                <option value="7">{{ 'admin.log.period.woche' | translate }}</option>
                <option value="30">{{ 'admin.log.period.monat' | translate }}</option>
                <option value="365">{{ 'admin.log.period.jahr' | translate }}</option>
                <option value="0">{{ 'admin.log.period.alle' | translate }}</option>
              </select>
            </div>
            <div class="col-sm-3">
              <button (click)="getLogFile()" class="btn btn-sm btn-default">{{ 'admin.log.download' | translate }}</button>
            </div>
          </div>
        </div>
      </app-tab>
      <app-tab tabId="i18n" [tabTitle]="'admin.i18n.tab' | translate">
        <div class="tab-content tab-border">
          <app-i18n-settings></app-i18n-settings>
        </div>
      </app-tab>
    </app-tabs>
  </section>
</div>
