import {ImportGrund} from "./import-grund";
import {Praeparat} from "./praeparat";
import { Status } from "@app/shared/classes/status";
import { Land } from "@app/shared/classes/land";
import { DokumentRequired } from "@app/shared/classes/dokument-required";
import { MeldungArt } from "@app/shared/classes/meldung-art";
import { NotizBlv } from "@app/shared/classes/notiz-blv";

export interface MeldungJSON {
  id: number;
  art: MeldungArt;
  nummer: string;
  erfDate: Date;
  uid: string;
  burNr: number;
  praxis: string;
  vorname: string;
  nachname: string;
  email: string;
  telefon: string;
  grund: ImportGrund;
  grundKommentar: string;
  importLand: Land;
  haendlerAusland: string;
  hauptIndikation: string;
  kommentar: string;
  praeparat: Praeparat;
  status: Status;
  stornoKommentar: string;
  highlight: string;
  neueDokumente: number[];
  requiredDokumente: DokumentRequired;
  notiz: NotizBlv;
}

export class Meldung {
  id: number;
  art: MeldungArt;
  nummer: string;
  erfDate: Date;
  uid: string;
  burNr: number;
  praxis: string;
  vorname: string;
  nachname: string;
  email: string;
  telefon: string;
  grund: ImportGrund;
  grundKommentar: string;
  importLand: Land;
  haendlerAusland: string;
  hauptIndikation: string;
  kommentar: string;
  praeparat: Praeparat = new Praeparat();
  status: Status;
  stornoKommentar: string;
  highlight: string;
  neueDokumente: number[];
  requiredDokumente: DokumentRequired;
  notiz: NotizBlv;

  public constructor(init?: Partial<Meldung>) {
    Object.assign(this, init);
  }

  static fromJSON(json: string | object): Meldung {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const meldung = Object.create(Meldung.prototype);
    return Object.assign(meldung, object);
  }

  toJSON(): MeldungJSON {
    return Object.assign({}, this);
  }
}
