import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Settings } from '@app/core/config/settings';
import { BackendService } from '@app/shared/services/backend.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-admin-settings-entry]',
  templateUrl: './admin-settings-entry.component.html'
})
export class AdminSettingsEntryComponent {
  @Output() deleted = new EventEmitter();

  settingsForm: UntypedFormGroup;
  isLoading = false;
  delChosen = false;

  setting: Settings;

  constructor(private backendService: BackendService,
              private translateService: TranslateService,
              private formBuilder: UntypedFormBuilder) {
  }

  @Output() reloadEntries: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set set(setting: Settings) {
    this.setting = setting;
    this.initForm(setting);
  }

  private initForm(setting: Settings) {
    this.settingsForm = this.formBuilder.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    });
    this.updateForm(setting);
  }

  private updateForm(setting: Settings) {
    this.settingsForm.reset({
      key: setting.key,
      value: setting.value,
      description: setting.description
    }, {emitEvent: false});
  }

  onSubmit() {
    const formValues = this.settingsForm.value;
    this.setting.key = formValues.key;
    this.setting.value = formValues.value;
    this.backendService.editSetting(this.setting)
      .subscribe({
        next: result => {
          this.setting = result;
          this.reloadEntries.emit();
        },
        error: console.error
      });
  }

  onReset() {
    this.updateForm(this.setting);
  }

  deleteSetting(setId: number) {
    this.delChosen = true;
    Swal.fire({
      text: this.translateService.instant('admin.settings.delete.warning'),
      icon: 'warning',
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('admin.settings.delete.yes'),
      cancelButtonText: this.translateService.instant('global.cancel')
    }).then((result) => {
      if (result.value) {
        this.continueDeleteSetting(setId);
      } else {
        this.delChosen = false;
      }
    });
  }

  continueDeleteSetting(setId: number) {
    this.backendService.deleteSetting(setId)
      .subscribe({
        next: () => {
          this.showAlert(true);
          this.deleted.emit();
        },
        error: error => {
          this.showAlert(false);
          console.error(error.message);
        }
      });
  }

  showAlert(ok: boolean) {
    let message = this.translateService.instant('admin.settings.delete.success');
    let apiResult: SweetAlertIcon = 'success';
    if (!ok) {
      message = this.translateService.instant('admin.settings.delete.error');
      apiResult = 'error';
    }
    Swal.fire({
      text: message,
      icon: apiResult,
      buttonsStyling: true,
      confirmButtonText: this.translateService.instant('global.ok')
    });
  }
}
