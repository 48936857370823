export class Login {
  userId: string;
  vorname?: string;
  nachname?: string;
  email?: string;
  anschrift?: string;
  uid?: string;
  burNr?: number;
  language: string;
  roles?: string[];

  static fromJSON(json: string | object): Login {
    if (!json) {
      return null;
    }
    const object = typeof json === 'string' ? JSON.parse(json) : json;
    const user = Object.create(Login.prototype);
    return Object.assign(user, object);
  }
}

export class AuthResponse {
  user: Login;
  roles: string[];
}
